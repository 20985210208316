var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("header", { staticClass: "pos-header bg-white" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row align-items-center" }, [
          _c("div", { staticClass: "col-xl-4 col-lg-4 col-md-6" }, [
            _c("div", { staticClass: "greeting-text" }, [
              _c(
                "h3",
                {
                  staticClass: "card-label mb-0 font-weight-bold text-primary"
                },
                [_vm._v("WELCOME\n\t\t\t\t\t")]
              ),
              _vm._v(" "),
              _c("h3", { staticClass: "card-label mb-0 " }, [
                _vm._v(
                  "\n\t\t\t\t\t\t" + _vm._s(_vm.login_name) + "\n\t\t\t\t\t"
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-xl-4 col-lg-3 col-md-12  order-lg-last order-second"
            },
            [
              _c("div", { staticClass: "topbar justify-content-end" }, [
                _c("div", { staticClass: "dropdown mega-dropdown" }, [
                  _c(
                    "div",
                    {
                      staticClass: "topbar-item ",
                      attrs: {
                        id: "id2",
                        "data-toggle": "dropdown",
                        "data-display": "static"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "btn btn-icon w-auto h-auto btn-clean d-flex align-items-center py-0 mr-3"
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "symbol symbol-35 symbol-light-success"
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "symbol-label bg-primary  font-size-h5 "
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "bi bi-calculator-fill",
                                      attrs: {
                                        xmlns: "http://www.w3.org/2000/svg",
                                        width: "20px",
                                        height: "20px",
                                        fill: "#fff",
                                        viewBox: "0 0 16 16"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm2 .5v2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0-.5.5zm0 4v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zM4.5 9a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM4 12.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zM7.5 6a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM7 9.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm.5 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM10 6.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm.5 2.5a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5h-1z"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm._m(1)
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "topbar-item folder-data" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "btn btn-icon  w-auto h-auto btn-clean d-flex align-items-center py-0 mr-3",
                      attrs: {
                        "data-toggle": "modal",
                        "data-target": "#folderpop"
                      },
                      on: {
                        click: function($event) {
                          return _vm.showDraftOrderModal()
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "badge badge-pill badge-primary" },
                        [_vm._v(_vm._s(_vm.draftOrders.length))]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "symbol symbol-35  symbol-light-success"
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "symbol-label bg-warning font-size-h5 "
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "20px",
                                    height: "20px",
                                    xmlns: "http://www.w3.org/2000/svg",
                                    fill: "#ffff",
                                    viewBox: "0 0 16 16"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z"
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown" }, [
                  _c(
                    "div",
                    {
                      staticClass: "topbar-item",
                      attrs: {
                        "data-toggle": "dropdown",
                        "data-display": "static"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "btn btn-icon w-auto h-auto btn-clean d-flex align-items-center py-0"
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "symbol symbol-35 symbol-light-success"
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "symbol-label font-size-h5 " },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "bi bi-person-fill",
                                      attrs: {
                                        width: "20px",
                                        height: "20px",
                                        viewBox: "0 0 16 16",
                                        fill: "currentColor",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d:
                                            "M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dropdown-menu dropdown-menu-right",
                      staticStyle: { "min-width": "150px" }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              return _vm.logout()
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "svg-icon svg-icon-xl svg-icon-primary mr-2"
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "feather feather-power",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "20px",
                                    height: "20px",
                                    viewBox: "0 0 24 24",
                                    fill: "none",
                                    stroke: "currentColor",
                                    "stroke-width": "2",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M18.36 6.64a9 9 0 1 1-12.73 0"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("line", {
                                    attrs: {
                                      x1: "12",
                                      y1: "2",
                                      x2: "12",
                                      y2: "12"
                                    }
                                  })
                                ]
                              )
                            ]
                          ),
                          _vm._v("\n\t\t\t\t\t\t\t Logout\n\t\t\t\t\t\t ")
                        ]
                      )
                    ]
                  )
                ])
              ])
            ]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "contentPOS" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xl-4 order-xl-first order-last" }, [
            _c(
              "div",
              { staticClass: "card card-custom gutter-b bg-white border-0" },
              [
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between colorfull-select"
                    },
                    [
                      _c("div", { staticClass: "selectmain" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.select_category,
                                expression: "select_category"
                              }
                            ],
                            staticClass: "arabic-select w-150px bag-primary",
                            on: {
                              change: [
                                function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.select_category = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                function($event) {
                                  return _vm.showSideBarProduct()
                                }
                              ]
                            }
                          },
                          [
                            _c("option", { attrs: { value: "all" } }, [
                              _vm._v("All")
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.categories, function(category) {
                              return [
                                _c(
                                  "option",
                                  { domProps: { value: category.id } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        category.detail
                                          ? category.detail[0].name
                                          : ""
                                      )
                                    )
                                  ]
                                )
                              ]
                            })
                          ],
                          2
                        )
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "product-items" }, [
                  _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.category_products, function(category_product) {
                      return _c(
                        "div",
                        {
                          staticClass:
                            "col-xl-4 col-lg-2 col-md-3 col-sm-4 col-6"
                        },
                        [
                          _c("div", { staticClass: "productCard" }, [
                            _c("div", { staticClass: "productThumb" }, [
                              _c("img", {
                                staticClass: "img-fluid",
                                attrs: {
                                  src: "/gallary/" + category_product.image,
                                  alt: "ix"
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "productContent" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "#",
                                    attr:
                                      category_product.product_combination_id
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.addProductInList(
                                        category_product.product_id,
                                        category_product.product_combination_id
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                      _vm._s(category_product.product_name) +
                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                  )
                                ]
                              )
                            ])
                          ])
                        ]
                      )
                    }),
                    0
                  )
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xl-5 col-lg-8 col-md-8" }, [
            _c("div", {}, [
              _c(
                "div",
                {
                  staticClass:
                    "card card-custom gutter-b bg-white border-0 table-contentpos"
                },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between colorfull-select"
                      },
                      [
                        _c("div", { staticClass: "selectmain" }, [
                          _c("label", { staticClass: "text-dark d-flex" }, [
                            _vm._v(
                              "Choose a Customer \n\t\t\t\t\t\t\t\t\t\t\t"
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "badge badge-secondary white rounded-circle",
                                attrs: {
                                  "data-toggle": "modal",
                                  "data-target": "#choosecustomer"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.showCustomerModel()
                                  }
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "svg-sm",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      "xmlns:xlink":
                                        "http://www.w3.org/1999/xlink",
                                      version: "1.1",
                                      id: "Layer_122",
                                      x: "0px",
                                      y: "0px",
                                      width: "512px",
                                      height: "512px",
                                      viewBox: "0 0 512 512",
                                      "enable-background": "new 0 0 512 512",
                                      "xml:space": "preserve"
                                    }
                                  },
                                  [
                                    _c("g", [
                                      _c("rect", {
                                        attrs: {
                                          x: "234.362",
                                          y: "128",
                                          width: "43.263",
                                          height: "256"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("rect", {
                                        attrs: {
                                          x: "128",
                                          y: "234.375",
                                          width: "256",
                                          height: "43.25"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedCustomer,
                                  expression: "selectedCustomer"
                                }
                              ],
                              staticClass: "arabic-select select-down ",
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.selectedCustomer = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            _vm._l(_vm.customers, function(customer) {
                              return _c(
                                "option",
                                {
                                  domProps: {
                                    value: {
                                      id: customer.customer_id,
                                      text: customer.customer_first_name
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(customer.customer_first_name) +
                                      " " +
                                      _vm._s(customer.customer_last_name) +
                                      " " +
                                      _vm._s(customer.customer_id)
                                  )
                                ]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _vm.errors.has("customer_id")
                            ? _c("small", {
                                staticClass: "form-text text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.errors.get("customer_id")
                                  )
                                }
                              })
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex flex-column selectmain" },
                          [
                            _c("label", { staticClass: "text-dark d-flex" }, [
                              _vm._v("Warehouse\n\t\t\t\t\t\t\t\t\t")
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedWarehouse,
                                    expression: "selectedWarehouse"
                                  }
                                ],
                                staticClass: "arabic-select select-down ",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.selectedWarehouse = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  }
                                }
                              },
                              _vm._l(_vm.warehouses, function(warehouse) {
                                return _c(
                                  "option",
                                  {
                                    domProps: {
                                      value: {
                                        id: warehouse.warehouse_id,
                                        text: warehouse.warehouse_name
                                      },
                                      selected: warehouse.warehouse_id == 1
                                    }
                                  },
                                  [_vm._v(_vm._s(warehouse.warehouse_name))]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _vm.errors.has("warehouse_id")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("warehouse_id")
                                    )
                                  }
                                })
                              : _vm._e()
                          ]
                        )
                      ]
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "card card-custom gutter-b bg-white border-0 table-contentpos"
                },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "form-group row mb-0" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("label", [_vm._v("Select Product")]),
                        _vm._v(" "),
                        _c(
                          "fieldset",
                          {
                            staticClass:
                              "form-group mb-0 d-flex barcodeselection"
                          },
                          [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.sortBy,
                                    expression: "sortBy"
                                  }
                                ],
                                staticClass: "form-control w-25",
                                attrs: { id: "exampleFormControlSelect1" },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.sortBy = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "name" } }, [
                                  _vm._v("Name")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "sku" } }, [
                                  _vm._v("SKU")
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              staticClass: "form-control border-dark",
                              class: _vm.sortBy == "name" ? "" : "d-none",
                              attrs: {
                                list: "browsers",
                                type: "text",
                                id: "basicInput1",
                                placeholder: ""
                              },
                              on: {
                                input: function($event) {
                                  return _vm.getSearchDataDetail()
                                },
                                keyup: function($event) {
                                  return _vm.searchProduct($event.target.value)
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "datalist",
                              { attrs: { id: "browsers" } },
                              _vm._l(_vm.searchFilters, function(searchFilter) {
                                return _c(
                                  "option",
                                  {
                                    attrs: {
                                      product: searchFilter.product,
                                      product_id: searchFilter.product_id,
                                      product_combination_id:
                                        searchFilter.product_combination_id
                                    },
                                    domProps: {
                                      value: searchFilter.product_name
                                    }
                                  },
                                  [_vm._v(_vm._s(searchFilter.product_name))]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c("input", {
                              staticClass: "form-control border-dark",
                              class: _vm.sortBy == "name" ? "d-none" : "",
                              attrs: {
                                list: "browsers1",
                                type: "text",
                                id: "basicInput2",
                                placeholder: ""
                              },
                              on: {
                                input: function($event) {
                                  return _vm.getSearchDataDetail()
                                },
                                keyup: function($event) {
                                  return _vm.searchProduct($event.target.value)
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "datalist",
                              { attrs: { id: "browsers1" } },
                              _vm._l(_vm.searchFilters, function(searchFilter) {
                                return _c(
                                  "option",
                                  {
                                    attrs: {
                                      product: searchFilter.product,
                                      product_id: searchFilter.product_id,
                                      product_combination_id:
                                        searchFilter.product_combination_id
                                    },
                                    domProps: {
                                      value: searchFilter.product
                                        ? searchFilter.product.sku
                                        : ""
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        searchFilter.product
                                          ? searchFilter.product.sku
                                          : ""
                                      )
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "table-datapos" }, [
                    _c(
                      "div",
                      {
                        staticClass: "table-responsive",
                        attrs: { id: "printableTable" }
                      },
                      [
                        _c(
                          "table",
                          {
                            staticClass: "display",
                            staticStyle: { width: "100%" },
                            attrs: { id: "orderTable" }
                          },
                          [
                            _vm._m(2),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(_vm.add_to_cart_products, function(
                                add_to_cart_product
                              ) {
                                return _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(add_to_cart_product.product_name)
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(add_to_cart_product.price))
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c("input", {
                                      staticClass:
                                        "form-control border-dark w-100px",
                                      attrs: {
                                        type: "number",
                                        placeholder: ""
                                      },
                                      domProps: {
                                        value: add_to_cart_product.qty
                                      },
                                      on: {
                                        keyup: function($event) {
                                          return _vm.qtyInc(
                                            add_to_cart_product.product_id,
                                            add_to_cart_product.product_combination_id,
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(add_to_cart_product.discount_show)
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(add_to_cart_product.subtotal))
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "card-toolbar text-right"
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "confirm-delete",
                                            attrs: {
                                              href: "#",
                                              title: "Delete"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.removeProduct(
                                                  add_to_cart_product.product_id,
                                                  add_to_cart_product.product_combination_id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-trash-alt"
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              }),
                              0
                            )
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "form-group row mb-0" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-md-12 btn-submit d-flex justify-content-end"
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-danger mr-2 confirm-delete",
                              attrs: { type: "submit", title: "Delete" },
                              on: {
                                click: function($event) {
                                  return _vm.emptyValue()
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fas fa-trash-alt mr-2" }),
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t\t\t\tSuspand/Cancel\n\t\t\t\t\t\t\t\t\t\t\t"
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary white",
                              attrs: { type: "submit" },
                              on: {
                                click: function($event) {
                                  return _vm.draftOrder()
                                }
                              }
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "bi bi-folder-fill svg-sm mr-2",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    fill: "currentColor",
                                    viewBox: "0 0 16 16"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z"
                                    }
                                  })
                                ]
                              ),
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t\t\t\tDraft Order\n\t\t\t\t\t\t\t\t\t\t\t"
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  ])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xl-3 col-lg-4 col-md-4" }, [
            _c(
              "div",
              { staticClass: "card card-custom gutter-b bg-white border-0" },
              [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "shop-profile" }, [
                    _c("div", { staticClass: "media" }, [
                      _vm._m(3),
                      _vm._v(" "),
                      _c("div", { staticClass: "media-body ml-3" }, [
                        _c("h3", { staticClass: "title font-weight-bold" }, [
                          _vm._v("The Kundol Shop")
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "phoonenumber" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                              _vm._s(_vm.businessPhone) +
                              "\n\t\t\t\t\t\t\t\t\t\t"
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "adddress" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                              _vm._s(_vm.businessAddress) +
                              "\n\t\t\t\t\t\t\t\t\t\t"
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "resulttable-pos" }, [
                    _c("table", { staticClass: "table right-table" }, [
                      _c("tbody", [
                        _c(
                          "tr",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-between"
                          },
                          [
                            _c(
                              "th",
                              {
                                staticClass:
                                  "border-0 font-size-h5 mb-0 font-size-bold text-dark"
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t\t\t\tTotal Items\n\t\t\t\t\t\t\t\t\t\t"
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "border-0 justify-content-end d-flex text-dark font-size-base"
                              },
                              [_vm._v(_vm._s(_vm.add_to_cart_products.length))]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "tr",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-between"
                          },
                          [
                            _c(
                              "th",
                              {
                                staticClass:
                                  "border-0 font-size-h5 mb-0 font-size-bold text-dark"
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t\t\t\tSubtotal\n\t\t\t\t\t\t\t\t\t\t"
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "border-0 justify-content-end d-flex text-dark font-size-base"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.add_to_cart_products.reduce(function(
                                      acc,
                                      item
                                    ) {
                                      return acc + item.subtotal
                                    },
                                    0)
                                  )
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "tr",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-between"
                          },
                          [
                            _c("th", { staticClass: "border-0 " }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-center font-size-h5 mb-0 font-size-bold text-dark"
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\t\t\tDISCOUNT(" +
                                      _vm._s(_vm.dicount_per_apply) +
                                      "%)\n\t\t\t\t\t\t\t\t\t\t\t"
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "badge badge-secondary white rounded-circle ml-2",
                                      attrs: {
                                        "data-toggle": "modal",
                                        "data-target": "#discountpop"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.showDiscountModel()
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass: "svg-sm",
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            "xmlns:xlink":
                                              "http://www.w3.org/1999/xlink",
                                            version: "1.1",
                                            id: "Layer_31",
                                            x: "0px",
                                            y: "0px",
                                            width: "512px",
                                            height: "512px",
                                            viewBox: "0 0 512 512",
                                            "enable-background":
                                              "new 0 0 512 512",
                                            "xml:space": "preserve"
                                          }
                                        },
                                        [
                                          _c("g", [
                                            _c("rect", {
                                              attrs: {
                                                x: "234.362",
                                                y: "128",
                                                width: "43.263",
                                                height: "256"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("rect", {
                                              attrs: {
                                                x: "128",
                                                y: "234.375",
                                                width: "256",
                                                height: "43.25"
                                              }
                                            })
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "border-0 justify-content-end d-flex text-dark font-size-base"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    (_vm.add_to_cart_products.reduce(function(
                                      acc,
                                      item
                                    ) {
                                      return acc + item.subtotal
                                    },
                                    0) /
                                      100) *
                                      _vm.dicount_per_apply
                                  )
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "tr",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-between"
                          },
                          [
                            _c("th", { staticClass: "border-0" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-center font-size-h5 mb-0 font-size-bold text-dark"
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\t\t    Tax\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "badge badge-secondary white rounded-circle ml-2",
                                      attrs: {
                                        "data-toggle": "modal",
                                        "data-target": "#shippingcost"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.showTaxModel()
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass: "svg-sm",
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            "xmlns:xlink":
                                              "http://www.w3.org/1999/xlink",
                                            version: "1.1",
                                            id: "Layer_11",
                                            x: "0px",
                                            y: "0px",
                                            width: "512px",
                                            height: "512px",
                                            viewBox: "0 0 512 512",
                                            "enable-background":
                                              "new 0 0 512 512",
                                            "xml:space": "preserve"
                                          }
                                        },
                                        [
                                          _c("g", [
                                            _c("rect", {
                                              attrs: {
                                                x: "234.362",
                                                y: "128",
                                                width: "43.263",
                                                height: "256"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("rect", {
                                              attrs: {
                                                x: "128",
                                                y: "234.375",
                                                width: "256",
                                                height: "43.25"
                                              }
                                            })
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "border-0 justify-content-end d-flex text-dark font-size-base"
                              },
                              [_vm._v(_vm._s(_vm.tax_per_apply))]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "tr",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-between item-price"
                          },
                          [
                            _c(
                              "th",
                              {
                                staticClass:
                                  "border-0 font-size-h5 mb-0 font-size-bold text-primary"
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\tTOTAL\n\t\t\t\t\t\t\t\t\t\t"
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "border-0 justify-content-end d-flex text-primary font-size-base"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.add_to_cart_products.reduce(function(
                                      acc,
                                      item
                                    ) {
                                      return acc + item.subtotal
                                    },
                                    0) -
                                      +(
                                        (_vm.add_to_cart_products.reduce(
                                          function(acc, item) {
                                            return acc + item.subtotal
                                          },
                                          0
                                        ) /
                                          100) *
                                        _vm.dicount_per_apply
                                      ) +
                                      +_vm.tax_per_apply
                                  )
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-end align-items-center flex-column buttons-cash"
                    },
                    [
                      _c("div", [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-primary white mb-2",
                            attrs: {
                              href: "#",
                              "data-toggle": "modal",
                              "data-target": "#payment-popup"
                            },
                            on: {
                              click: function($event) {
                                return _vm.saveTransaction()
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fas fa-money-bill-wave mr-2"
                            }),
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\t\tPay With Cash\n\t\t\t\t\t\t\t\t\t"
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "d-none" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-outline-secondary ",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                return _vm.showDraftOrderModal()
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-credit-card mr-2" }),
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\t\tPay With Card\n\t\t\t\t\t\t\t\t\t"
                            )
                          ]
                        )
                      ])
                    ]
                  )
                ])
              ]
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", {
      staticClass: "modal-backdrop",
      class: _vm.discountModel == 1 ? "show" : "d-none"
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "text-left modal",
        class: _vm.discountModel == 1 ? "d-block" : "d-none",
        attrs: { role: "dialog", "aria-modal": "true", tabindex: "-1" }
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _vm._m(4),
              _c(
                "button",
                {
                  staticClass:
                    "close rounded-pill btn btn-sm btn-icon btn-light btn-hover-primary m-0",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close"
                  },
                  on: {
                    click: function($event) {
                      return _vm.showDiscountModel()
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "bi bi-x",
                      attrs: {
                        width: "20px",
                        height: "20px",
                        viewBox: "0 0 16 16",
                        fill: "currentColor",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          d:
                            "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                        }
                      })
                    ]
                  )
                ]
              )
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("label", { staticClass: "text-body" }, [
                    _vm._v("Discount")
                  ]),
                  _c("fieldset", { staticClass: "form-group mb-3 d-flex" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.dicount_per,
                          expression: "dicount_per"
                        }
                      ],
                      staticClass: "form-control bg-white",
                      attrs: {
                        type: "text",
                        name: "text",
                        id: "exampleInputText",
                        "aria-describedby": "textHelp",
                        placeholder: "Enter Discount"
                      },
                      domProps: { value: _vm.dicount_per },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.dicount_per = $event.target.value
                        }
                      }
                    }),
                    _c(
                      "a",
                      {
                        staticClass:
                          "btn-secondary btn ml-2 white pt-1 pb-1 d-flex align-items-center justify-content-center",
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            return _vm.discountApply()
                          }
                        }
                      },
                      [_vm._v("Apply")]
                    )
                  ])
                ])
              ])
            ])
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "modal-backdrop",
      class: _vm.taxModel == 1 ? "show" : "d-none"
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "text-left modal",
        class: _vm.taxModel == 1 ? "d-block" : "d-none",
        attrs: { role: "dialog", "aria-modal": "true", tabindex: "-1" }
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _vm._m(5),
              _c(
                "button",
                {
                  staticClass:
                    "close rounded-pill btn btn-sm btn-icon btn-light btn-hover-primary m-0",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close"
                  },
                  on: {
                    click: function($event) {
                      return _vm.showTaxModel()
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "bi bi-x",
                      attrs: {
                        width: "20px",
                        height: "20px",
                        viewBox: "0 0 16 16",
                        fill: "currentColor",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          d:
                            "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                        }
                      })
                    ]
                  )
                ]
              )
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("label", { staticClass: "text-body" }, [
                    _vm._v("Discount")
                  ]),
                  _c("fieldset", { staticClass: "form-group mb-3 d-flex" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.tax_val,
                          expression: "tax_val"
                        }
                      ],
                      staticClass: "form-control bg-white",
                      attrs: {
                        type: "text",
                        name: "text",
                        id: "exampleInputText",
                        "aria-describedby": "textHelp",
                        placeholder: "Enter Discount"
                      },
                      domProps: { value: _vm.tax_val },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.tax_val = $event.target.value
                        }
                      }
                    }),
                    _c(
                      "a",
                      {
                        staticClass:
                          "btn-secondary btn ml-2 white pt-1 pb-1 d-flex align-items-center justify-content-center",
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            return _vm.taxApply()
                          }
                        }
                      },
                      [_vm._v("Apply")]
                    )
                  ])
                ])
              ])
            ])
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "modal-backdrop show",
      class: _vm.draftModel == 1 ? "show" : "d-none"
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "text-left modal",
        class: _vm.draftModel == 1 ? "d-block" : "d-none",
        attrs: { role: "dialog", "aria-modal": "true", tabindex: "-1" }
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _vm._m(6),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "close rounded-pill btn btn-sm btn-icon btn-light btn-hover-primary m-0",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close"
                  },
                  on: {
                    click: function($event) {
                      return _vm.showDraftOrderModal()
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "bi bi-x",
                      attrs: {
                        width: "20px",
                        height: "20px",
                        viewBox: "0 0 16 16",
                        fill: "currentColor",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          d:
                            "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                        }
                      })
                    ]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "modal-body pos-ordermain" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-lg-4" },
                    _vm._l(_vm.draftOrders, function(draftOrder, index) {
                      return _c("div", { staticClass: "pos-order" }, [
                        _c(
                          "h3",
                          {
                            staticClass: "pos-order-title",
                            attrs: { id: "myModalLabel1" }
                          },
                          [_vm._v("Order " + _vm._s(index + 1))]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "orderdetail-pos" }, [
                          _c("p", [
                            _c("strong", [_vm._v("Customer Name ")]),
                            _vm._v(_vm._s(draftOrder.customer_name))
                          ]),
                          _vm._v(" "),
                          _vm._m(7, true),
                          _vm._v(" "),
                          _c("p", [
                            _c("strong", [_vm._v("Total Items ")]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  draftOrder.product
                                    ? draftOrder.product.length
                                    : 0
                                )
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _c("strong", [_vm._v("Amount to Pay ")]),
                            _vm._v(
                              " $" +
                                _vm._s(
                                  draftOrder.product
                                    ? draftOrder.product.reduce(function(
                                        acc,
                                        item
                                      ) {
                                        return acc + item.subtotal
                                      },
                                      0) -
                                        +(
                                          (draftOrder.product.reduce(function(
                                            acc,
                                            item
                                          ) {
                                            return acc + item.subtotal
                                          },
                                          0) /
                                            100) *
                                          draftOrder.dicount_per_apply
                                        ) +
                                        +draftOrder.tax_per_apply
                                    : 0
                                )
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-end" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "confirm-delete ml-3",
                                attrs: { href: "#", title: "edit" },
                                on: {
                                  click: function($event) {
                                    return _vm.editDraft(draftOrder.id)
                                  }
                                }
                              },
                              [_c("i", { staticClass: "fas fa-edit" })]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "confirm-delete ml-3",
                                attrs: { href: "#", title: "Delete" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteDraft(draftOrder.id)
                                  }
                                }
                              },
                              [_c("i", { staticClass: "fas fa-trash-alt" })]
                            )
                          ]
                        )
                      ])
                    }),
                    0
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _vm._m(8)
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "text-left modal",
        class: _vm.customerModel == 1 ? "d-block" : "d-none",
        attrs: { role: "dialog", "aria-modal": "true", tabindex: "-1" }
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _vm._m(9),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "close rounded-pill btn btn-sm btn-icon btn-light btn-hover-primary m-0",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close"
                  },
                  on: {
                    click: function($event) {
                      return _vm.showCustomerModel()
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "bi bi-x",
                      attrs: {
                        width: "20px",
                        height: "20px",
                        viewBox: "0 0 16 16",
                        fill: "currentColor",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          d:
                            "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                        }
                      })
                    ]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("form", [
                _c("div", { staticClass: "form-group row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", { staticClass: "text-body" }, [
                      _vm._v("First Name")
                    ]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.customer_info.first_name,
                            expression: "customer_info.first_name"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "first_name",
                          id: "exampleInputText",
                          "aria-describedby": "textHelp",
                          placeholder: "Enter First Name"
                        },
                        domProps: { value: _vm.customer_info.first_name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.customer_info,
                              "first_name",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.has("first_name")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("first_name"))
                            }
                          })
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", { staticClass: "text-body" }, [
                      _vm._v("Last Name")
                    ]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.customer_info.last_name,
                            expression: "customer_info.last_name"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "last_name",
                          id: "exampleInputText",
                          "aria-describedby": "textHelp",
                          placeholder: "Enter Last Name"
                        },
                        domProps: { value: _vm.customer_info.last_name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.customer_info,
                              "last_name",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.has("last_name")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("last_name"))
                            }
                          })
                        : _vm._e()
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", { staticClass: "text-body" }, [
                      _vm._v("Email")
                    ]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.customer_info.email,
                            expression: "customer_info.email"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "email",
                          id: "exampleInputText",
                          "aria-describedby": "textHelp",
                          placeholder: "Enter Email"
                        },
                        domProps: { value: _vm.customer_info.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.customer_info,
                              "email",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.has("email")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("email"))
                            }
                          })
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", { staticClass: "text-body" }, [
                      _vm._v("Password")
                    ]),
                    _vm._v(" "),
                    _c("fieldset", { staticClass: "form-group mb-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.customer_info.password,
                            expression: "customer_info.password"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "password",
                          name: "password",
                          id: "exampleInputText",
                          "aria-describedby": "textHelp",
                          placeholder: "Enter Password"
                        },
                        domProps: { value: _vm.customer_info.password },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.customer_info,
                              "password",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.has("password")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("password"))
                            }
                          })
                        : _vm._e()
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group row justify-content-end mb-0" },
                  [
                    _c("div", { staticClass: "col-md-6  text-right" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              return _vm.saveCustomer()
                            }
                          }
                        },
                        [_vm._v("Add Customer")]
                      )
                    ])
                  ]
                )
              ])
            ])
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "modal-backdrop",
      class: _vm.customerModel == 1 ? "show" : "d-none"
    })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-xl-4 col-lg-5 col-md-6  clock-main" },
      [
        _c("div", { staticClass: "clock" }, [
          _c("div", { staticClass: "datetime-content" }, [
            _c("ul", [
              _c("li", { attrs: { id: "hours" } }),
              _vm._v(" "),
              _c("li", { attrs: { id: "point1" } }, [_vm._v(":")]),
              _vm._v(" "),
              _c("li", { attrs: { id: "min" } }),
              _vm._v(" "),
              _c("li", { attrs: { id: "point" } }, [_vm._v(":")]),
              _vm._v(" "),
              _c("li", { attrs: { id: "sec" } })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "datetime-content" }, [
            _c("div", { attrs: { id: "Date" } })
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "dropdown-menu dropdown-menu-right calu",
        staticStyle: { "min-width": "248px" }
      },
      [
        _c("div", { staticClass: "calculator" }, [
          _c("div", { staticClass: "input", attrs: { id: "input" } }),
          _vm._v(" "),
          _c("div", { staticClass: "buttons" }, [
            _c("div", { staticClass: "operators" }, [
              _c("div", [_vm._v("+")]),
              _vm._v(" "),
              _c("div", [_vm._v("-")]),
              _vm._v(" "),
              _c("div", [_vm._v("×")]),
              _vm._v(" "),
              _c("div", [_vm._v("÷")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _c("div", { staticClass: "leftPanel" }, [
                _c("div", { staticClass: "numbers" }, [
                  _c("div", [_vm._v("7")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("8")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("9")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "numbers" }, [
                  _c("div", [_vm._v("4")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("5")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("6")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "numbers" }, [
                  _c("div", [_vm._v("1")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("2")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("3")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "numbers" }, [
                  _c("div", [_vm._v("0")]),
                  _vm._v(" "),
                  _c("div", [_vm._v(".")]),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "clear" } }, [_vm._v("C")])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "equal", attrs: { id: "result" } }, [
                _vm._v("=")
              ])
            ])
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Price")]),
        _vm._v(" "),
        _c("th", [_vm._v("Quantity")]),
        _vm._v(" "),
        _c("th", [_vm._v("Discount")]),
        _vm._v(" "),
        _c("th", [_vm._v("Subtotal")]),
        _vm._v(" "),
        _c("th", { staticClass: " text-right no-sort" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "bg-primary w-100px h-100px d-flex justify-content-center align-items-center"
      },
      [_c("h2", { staticClass: "mb-0 white" }, [_vm._v("K")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-title h4" }, [
      _c("h3", { staticClass: "modal-title", attrs: { id: "myModalLabel1" } }, [
        _vm._v("Add Discount")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-title h4" }, [
      _c("h3", { staticClass: "modal-title", attrs: { id: "myModalLabel1" } }, [
        _vm._v("Add Tax")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-title h4" }, [
      _c("h3", { staticClass: "modal-title", attrs: { id: "myModalLabel1" } }, [
        _vm._v("Draft Orders")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Payment Status ")]),
      _vm._v("Pending")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer border-0 modal-footer" }, [
      _c("div", { staticClass: "row" }, [_c("div", { staticClass: "col-12" })])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-title h4" }, [
      _c("h3", { staticClass: "modal-title", attrs: { id: "myModalLabel1" } }, [
        _vm._v("Add Customer")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }