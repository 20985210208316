var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "card card-custom gutter-b bg-transparent shadow-none border-0"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-header align-items-center  border-bottom-dark px-0"
                      },
                      [
                        _vm._m(0),
                        _vm._v(" "),
                        _c("div", { staticClass: "icons d-flex" }, [
                          _vm.$parent.permissions.includes("account-manage")
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn ml-2 p-0 kt_notes_panel_toggle",
                                  attrs: {
                                    "data-toggle": "tooltip",
                                    title: "",
                                    "data-placement": "right",
                                    "data-original-title":
                                      "Check out more demos"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "bg-secondary h-30px font-size-h5 w-30px d-flex align-items-center justify-content-center  rounded-circle shadow-sm ",
                                      on: {
                                        click: function($event) {
                                          _vm.display_form = !_vm.display_form
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass: "bi bi-plus white",
                                          attrs: {
                                            width: "25px",
                                            height: "25px",
                                            viewBox: "0 0 16 16",
                                            fill: "currentColor",
                                            xmlns: "http://www.w3.org/2000/svg"
                                          }
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              "fill-rule": "evenodd",
                                              d:
                                                "M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "ml-2",
                              attrs: { href: "#", onclick: "printDiv()" }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "icon h-30px font-size-h5 w-30px d-flex align-items-center justify-content-center rounded-circle "
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "bi bi-printer-fill",
                                      attrs: {
                                        width: "15px",
                                        height: "15px",
                                        viewBox: "0 0 16 16",
                                        fill: "currentColor",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5z"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d:
                                            "M11 9H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d:
                                            "M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            { staticClass: "ml-2", attrs: { href: "#" } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "icon h-30px font-size-h5 w-30px d-flex align-items-center justify-content-center rounded-circle "
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass:
                                        "bi bi-file-earmark-text-fill",
                                      attrs: {
                                        width: "15px",
                                        height: "15px",
                                        viewBox: "0 0 16 16",
                                        fill: "currentColor",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d:
                                            "M2 2a2 2 0 0 1 2-2h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7 2l.5-2.5 3 3L10 5a1 1 0 0 1-1-1zM4.5 8a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5z"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 " }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0"
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: " table-responsive",
                            attrs: { id: "printableTable" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "dataTables_wrapper no-footer",
                                attrs: { id: "productaccountTable_wrapper" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "dataTables_length",
                                    attrs: { id: "productaccountTable_length" }
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        "Mostrar\n                                            "
                                      ),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.limit,
                                              expression: "limit"
                                            }
                                          ],
                                          attrs: {
                                            name: "productaccountTable_length",
                                            "aria-controls":
                                              "productaccountTable"
                                          },
                                          on: {
                                            change: [
                                              function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.limit = $event.target
                                                  .multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              },
                                              function($event) {
                                                return _vm.fetchaccounts()
                                              }
                                            ]
                                          }
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "10" } },
                                            [_vm._v("10")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "25" } },
                                            [_vm._v("25")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "50" } },
                                            [_vm._v("50")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "100" } },
                                            [_vm._v("100")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "200" } },
                                            [_vm._v("200")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "500" } },
                                            [_vm._v("500")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "1000" } },
                                            [_vm._v("1000")]
                                          )
                                        ]
                                      ),
                                      _vm._v(" Entradas")
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "dataTables_filter",
                                    attrs: { id: "productaccountTable_filter" }
                                  },
                                  [
                                    _c("label", [
                                      _vm._v("Buscar:"),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.searchParameter,
                                            expression: "searchParameter"
                                          }
                                        ],
                                        attrs: {
                                          type: "search",
                                          placeholder: "",
                                          "aria-controls": "productaccountTable"
                                        },
                                        domProps: {
                                          value: _vm.searchParameter
                                        },
                                        on: {
                                          keyup: function($event) {
                                            return _vm.fetchaccounts()
                                          },
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.searchParameter =
                                              $event.target.value
                                          }
                                        }
                                      })
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "table",
                                  {
                                    staticClass: "display dataTable no-footer",
                                    attrs: {
                                      id: "productaccountTable",
                                      role: "grid"
                                    }
                                  },
                                  [
                                    _c("thead", { staticClass: "text-body" }, [
                                      _c("tr", { attrs: { role: "row" } }, [
                                        _c(
                                          "th",
                                          {
                                            staticClass: "sorting",
                                            class:
                                              (this.$data.sortType == "asc" ||
                                                this.$data.sortType == "ASC") &&
                                              this.$data.sortBy == "id"
                                                ? "sorting_asc"
                                                : (this.$data.sortType ==
                                                    "desc" ||
                                                    this.$data.sortType ==
                                                      "DESC") &&
                                                  this.$data.sortBy == "id"
                                                ? "sorting_desc"
                                                : "sorting",
                                            attrs: {
                                              tabindex: "0",
                                              "aria-controls":
                                                "productaccountTable",
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-sort": "ascending",
                                              "aria-label":
                                                "ID: activate to sort column descending"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.sorting("id")
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                                Fecha\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "sorting",
                                            class:
                                              (this.$data.sortType == "asc" ||
                                                this.$data.sortType == "ASC") &&
                                              this.$data.sortBy == "name"
                                                ? "sorting_asc"
                                                : (this.$data.sortType ==
                                                    "desc" ||
                                                    this.$data.sortType ==
                                                      "DESC") &&
                                                  this.$data.sortBy == "nombre"
                                                ? "sorting_desc"
                                                : "sorting",
                                            attrs: {
                                              tabindex: "0",
                                              "aria-controls":
                                                "productaccountTable",
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label":
                                                "account: activate to sort column ascending"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.sorting("nombre")
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                            Empresa\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                                Despachante\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                            Confirmado\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                               N° Factura\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                              Monto\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                              N° Guias\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label": "Action"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                              Dest. De Despacho\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label": "Action"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                              Tipo de Carga\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label": "Action"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                              Chofer\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label": "Action"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                             Acciones\n                                                            "
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "tbody",
                                      {
                                        staticClass: "kt-table-tbody text-dark"
                                      },
                                      _vm._l(_vm.facturas, function(factura) {
                                        return _c(
                                          "tr",
                                          {
                                            key: factura.id,
                                            staticClass:
                                              "kt-table-row kt-table-row-level-0 odd",
                                            attrs: { role: "row" }
                                          },
                                          [
                                            _c(
                                              "td",
                                              { staticClass: "sorting_1" },
                                              [
                                                _vm._v(
                                                  "\n                                                                " +
                                                    _vm._s(factura.fecha) +
                                                    "\n                                                            "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(factura.empresa) +
                                                  "\n                                                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(factura.despachante) +
                                                  "\n                                                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "dropdown-item click-edit1",
                                                  attrs: {
                                                    href: "javascript:void(0)",
                                                    id: "click-edit1",
                                                    "data-toggle": "tooltip",
                                                    title: "",
                                                    "data-placement": "right",
                                                    "data-original-title":
                                                      "Check out more demos"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.editaccount(factura)
                                                      _vm.display_form_type =
                                                        "type1"
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fa fa-edit"
                                                  })
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(factura.estado) +
                                                  "\n                                                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(
                                                    factura.numero_factura
                                                  ) +
                                                  "\n                                                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(factura.monto) +
                                                  "\n                                                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(
                                                    factura.guias
                                                      .map(function(guia) {
                                                        return guia.numero
                                                          .toString()
                                                          .padStart(3, "0")
                                                      })
                                                      .join(", ")
                                                  ) +
                                                  "\n                                                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(factura.destino) +
                                                  "\n                                                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(factura.tipo_carga) +
                                                  "\n                                                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(factura.nombre) +
                                                  "\n                                                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            factura.id > 0
                                              ? _c("td", [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "dropdown-item",
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.deleteaccount(
                                                            factura.id
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-trash"
                                                      })
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "dropdown-item click-edit2",
                                                      attrs: {
                                                        href:
                                                          "javascript:void(0)",
                                                        id: "click-edit2",
                                                        "data-toggle":
                                                          "tooltip",
                                                        title: "",
                                                        "data-placement":
                                                          "right",
                                                        "data-original-title":
                                                          "Check out more demos"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.editestado(
                                                            factura
                                                          )
                                                          _vm.display_form_type =
                                                            "type2"
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-edit"
                                                      })
                                                    ]
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "pagination pagination-sm m-0 float-right"
                                  },
                                  [
                                    _c(
                                      "li",
                                      {
                                        class: [
                                          {
                                            disabled: !_vm.pagination
                                              .prev_page_url
                                          }
                                        ]
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "page-link",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                return _vm.fetchaccounts(
                                                  _vm.pagination.prev_page_url
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Previous")]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("li", { staticClass: "disabled" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "page-link text-dark",
                                          attrs: { href: "#" }
                                        },
                                        [
                                          _vm._v(
                                            "Page " +
                                              _vm._s(
                                                _vm.pagination.current_page
                                              ) +
                                              " of " +
                                              _vm._s(_vm.pagination.last_page)
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "page-item",
                                        class: [
                                          {
                                            disabled: !_vm.pagination
                                              .next_page_url
                                          }
                                        ]
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "page-link",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                return _vm.fetchaccounts(
                                                  _vm.pagination.next_page_url
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Next")]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    ])
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.display_form_type === "type1"
      ? _c(
          "div",
          {
            staticClass:
              "offcanvas offcanvas-right kt-color-panel p-8 kt_notes_panel",
            class: _vm.display_form ? "offcanvas-on" : "",
            attrs: { id: "click-edit1" }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "offcanvas-header d-flex align-items-center justify-content-between pb-3"
              },
              [
                _c("h4", { staticClass: "font-size-h4 font-weight-bold m-0" }, [
                  _vm._v("Cambiar de Estado")
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "btn btn-sm btn-icon btn-light btn-hover-primary kt_notes_panel_close",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        return _vm.clearForm()
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "bi bi-x",
                        attrs: {
                          width: "20px",
                          height: "20px",
                          viewBox: "0 0 16 16",
                          fill: "currentColor",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            d:
                              "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                          }
                        })
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("form", { attrs: { id: "myform" } }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card card-custom gutter-b bg-white border-0"
                        },
                        [
                          _c("div", { staticClass: "card-body" }, [
                            _c("form", [
                              _c("div", { staticClass: "form-group" }, [
                                _c("div", { staticClass: "col-12 col-sm-12" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-6 col-lg-6" },
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "destino" } },
                                          [_vm._v("Antertior Estado:")]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.v_estado,
                                              expression: "v_estado"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            id: "acuenta",
                                            placeholder: "Antertior Estado"
                                          },
                                          domProps: { value: _vm.v_estado },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.v_estado = $event.target.value
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-6 col-lg-6" },
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "origen" } },
                                          [_vm._v("Estado:")]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.estado,
                                              expression: "estado"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "checkbox",
                                            id: "total_pagar",
                                            placeholder: "Ingrese el estado"
                                          },
                                          domProps: {
                                            checked: Array.isArray(_vm.estado)
                                              ? _vm._i(_vm.estado, null) > -1
                                              : _vm.estado
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$a = _vm.estado,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.estado = $$a.concat([
                                                      $$v
                                                    ]))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.estado = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.estado = $$c
                                              }
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _vm.estado
                                          ? _c("p", [_vm._v("Estado pagado")])
                                          : _vm._e()
                                      ]
                                    )
                                  ])
                                ])
                              ])
                            ])
                          ])
                        ]
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.Updatestatus()
                    }
                  }
                },
                [_vm._v("Gaurdar")]
              )
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.display_form_type === "type2"
      ? _c(
          "div",
          {
            staticClass:
              "offcanvas offcanvas-right kt-color-panel p-8 kt_notes_panel",
            class: _vm.display_form ? "offcanvas-on" : "",
            attrs: { id: "click-edit2" }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "offcanvas-header d-flex align-items-center justify-content-between pb-3"
              },
              [
                _c("h4", { staticClass: "font-size-h4 font-weight-bold m-0" }, [
                  _vm._v("Editar Factura")
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "btn btn-sm btn-icon btn-light btn-hover-primary kt_notes_panel_close",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        return _vm.clearCanvas()
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "bi bi-x",
                        attrs: {
                          width: "20px",
                          height: "20px",
                          viewBox: "0 0 16 16",
                          fill: "currentColor",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            d:
                              "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                          }
                        })
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("form", { attrs: { id: "myform" } }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card card-custom gutter-b bg-white border-0"
                        },
                        [
                          _c("div", { staticClass: "card-body" }, [
                            _c("form", [
                              _c("div", { staticClass: "form-group" }, [
                                _c("div", { staticClass: "col-12 col-sm-12" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-6 col-lg-6" },
                                      [
                                        _c(
                                          "table",
                                          {
                                            staticClass: "table",
                                            staticStyle: { float: "left" }
                                          },
                                          [
                                            _vm._m(1),
                                            _vm._v(" "),
                                            _c("tbody", [
                                              _c("tr", [
                                                _c("td", [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.fecha,
                                                        expression: "fecha"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      type: "date",
                                                      id: "fecha"
                                                    },
                                                    domProps: {
                                                      value: _vm.fecha
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.fecha =
                                                          $event.target.value
                                                      }
                                                    }
                                                  })
                                                ])
                                              ])
                                            ])
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group" }, [
                                _c("div", { staticClass: "col-12 col-sm-12" }, [
                                  _c("div", { staticClass: "col-sm-12" }, [
                                    _c(
                                      "form",
                                      {
                                        on: {
                                          submit: function($event) {
                                            $event.preventDefault()
                                            return _vm.agregarGuia.apply(
                                              null,
                                              arguments
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "text-body" },
                                          [_vm._v("Guias")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "fieldset",
                                          {
                                            staticClass:
                                              "form-group mb-3 d-flex"
                                          },
                                          [
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.nguia,
                                                    expression: "nguia"
                                                  }
                                                ],
                                                staticClass:
                                                  "js-example-basic-single js-states form-control bg-transparent",
                                                attrs: { id: "my-select" },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.nguia = $event.target
                                                        .multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    },
                                                    function($event) {
                                                      return _vm.appendChild(
                                                        $event,
                                                        "select"
                                                      )
                                                    }
                                                  ]
                                                }
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  { attrs: { value: "" } },
                                                  [
                                                    _vm._v(
                                                      "Selecciona una opcion:"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm._l(
                                                  _vm.listadoguias,
                                                  function(listguia) {
                                                    return listguia.status == 1
                                                      ? _c(
                                                          "option",
                                                          {
                                                            key: listguia.id,
                                                            domProps: {
                                                              value:
                                                                listguia.id,
                                                              selected:
                                                                listguia.id
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "00" +
                                                                _vm._s(
                                                                  listguia.numero
                                                                ) +
                                                                "\n                                                                "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-primary",
                                            attrs: { type: "submit" }
                                          },
                                          [_vm._v("Agregar guia")]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "table",
                                      {
                                        staticClass:
                                          "table table-striped table-bordered table-hover",
                                        attrs: { width: "100%" }
                                      },
                                      [
                                        _vm._m(2),
                                        _vm._v(" "),
                                        _c(
                                          "tbody",
                                          _vm._l(_vm.guias, function(
                                            guia,
                                            index
                                          ) {
                                            return _c("tr", { key: index }, [
                                              _c("td", [
                                                _vm._v(
                                                  "00" + _vm._s(guia.numero)
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _vm._m(3, true)
                                            ])
                                          }),
                                          0
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-6 col-lg-6" },
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "destino" } },
                                          [_vm._v("Total:")]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.monto,
                                              expression: "monto"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            id: "correo",
                                            placeholder: "Ingrese el total"
                                          },
                                          domProps: { value: _vm.monto },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.monto = $event.target.value
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-6 col-lg-6" },
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "destino" } },
                                          [_vm._v("Despachante:")]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.despachante,
                                              expression: "despachante"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            id: "correo",
                                            placeholder: "Ingrese Despachante"
                                          },
                                          domProps: { value: _vm.despachante },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.despachante =
                                                $event.target.value
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group" }, [
                                _c("div", { staticClass: "col-12 col-sm-12" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-6 col-lg-6" },
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "origen" } },
                                          [_vm._v("N° Factura:")]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.numero_factura,
                                              expression: "numero_factura"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            id: "direccion",
                                            placeholder:
                                              "Ingrese su numero de factura"
                                          },
                                          domProps: {
                                            value: _vm.numero_factura
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.numero_factura =
                                                $event.target.value
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-6 col-lg-6" },
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "origen" } },
                                          [_vm._v("Tipo Carga:")]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.tipo_carga,
                                              expression: "tipo_carga"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            id: "direccion",
                                            placeholder:
                                              "Ingrese el tipo de carga"
                                          },
                                          domProps: { value: _vm.tipo_carga },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.tipo_carga =
                                                $event.target.value
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group" }, [
                                _c("div", { staticClass: "col-12 col-sm-12" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-6 col-lg-6" },
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "origen" } },
                                          [_vm._v("Estado:")]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.act_estado,
                                              expression: "act_estado"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "checkbox",
                                            id: "estado",
                                            placeholder: "Ingrese un monto"
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.act_estado
                                            )
                                              ? _vm._i(_vm.act_estado, null) >
                                                -1
                                              : _vm.act_estado
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$a = _vm.act_estado,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.act_estado = $$a.concat(
                                                      [$$v]
                                                    ))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.act_estado = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.act_estado = $$c
                                              }
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _vm.act_estado
                                          ? _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("estadoTexto")(
                                                    _vm.act_estado
                                                  )
                                                )
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ])
                                ])
                              ])
                            ])
                          ])
                        ]
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.Updatefacture()
                    }
                  }
                },
                [_vm._v("Gaurdar")]
              )
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-title mb-0" }, [
      _c("h3", { staticClass: "card-label mb-0 font-weight-bold text-body" }, [
        _vm._v(
          "\n                                        Listado de Facturas\n                                        "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [_c("th", { attrs: { width: "5%" } }, [_vm._v("Fecha")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "text-center table-primary1" }, [
        _c("th", { attrs: { width: "19%" } }, [_vm._v("Guia N°")]),
        _vm._v(" "),
        _c("th")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("i", {
        staticClass: "nav-icon i-Close-Window font-weight-bold text-danger",
        staticStyle: { cursor: "pointer" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }