var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "card card-custom gutter-b bg-transparent shadow-none border-0"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-header align-items-center  border-bottom-dark px-0"
                      },
                      [
                        _vm._m(0),
                        _vm._v(" "),
                        _c("div", { staticClass: "icons d-flex" }, [
                          _vm.$parent.permissions.includes("customer-manage")
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn ml-2 p-0 kt_notes_panel_toggle",
                                  attrs: {
                                    "data-toggle": "tooltip",
                                    title: "",
                                    "data-placement": "right",
                                    "data-original-title":
                                      "Check out more demos"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "bg-secondary h-30px font-size-h5 w-30px d-flex align-items-center justify-content-center  rounded-circle shadow-sm ",
                                      on: {
                                        click: function($event) {
                                          _vm.display_form = !_vm.display_form
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass: "bi bi-plus white",
                                          attrs: {
                                            width: "25px",
                                            height: "25px",
                                            viewBox: "0 0 16 16",
                                            fill: "currentColor",
                                            xmlns: "http://www.w3.org/2000/svg"
                                          }
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              "fill-rule": "evenodd",
                                              d:
                                                "M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      ]
                    )
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 " }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0"
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: " table-responsive",
                            attrs: { id: "printableTable" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "dataTables_wrapper no-footer",
                                attrs: { id: "productUnitTable_wrapper" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "dataTables_length",
                                    attrs: { id: "productUnitTable_length" }
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        "Show\n                                                        "
                                      ),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.limit,
                                              expression: "limit"
                                            }
                                          ],
                                          attrs: {
                                            name: "productUnitTable_length",
                                            "aria-controls": "productUnitTable"
                                          },
                                          on: {
                                            change: [
                                              function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.limit = $event.target
                                                  .multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              },
                                              function($event) {
                                                return _vm.fetchcustomers()
                                              }
                                            ]
                                          }
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "10" } },
                                            [_vm._v("10")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "25" } },
                                            [_vm._v("25")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "50" } },
                                            [_vm._v("50")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "100" } },
                                            [_vm._v("100")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "200" } },
                                            [_vm._v("200")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "500" } },
                                            [_vm._v("500")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "1000" } },
                                            [_vm._v("1000")]
                                          )
                                        ]
                                      ),
                                      _vm._v(" entries")
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "dataTables_filter",
                                    attrs: { id: "productUnitTable_filter" }
                                  },
                                  [
                                    _c("label", [
                                      _vm._v("Search:"),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.searchParameter,
                                            expression: "searchParameter"
                                          }
                                        ],
                                        attrs: {
                                          type: "search",
                                          placeholder: "",
                                          "aria-controls": "productUnitTable"
                                        },
                                        domProps: {
                                          value: _vm.searchParameter
                                        },
                                        on: {
                                          keyup: function($event) {
                                            return _vm.fetchcustomers()
                                          },
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.searchParameter =
                                              $event.target.value
                                          }
                                        }
                                      })
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "table",
                                  {
                                    staticClass: "display dataTable no-footer",
                                    attrs: {
                                      id: "productUnitTable",
                                      role: "grid"
                                    }
                                  },
                                  [
                                    _c("thead", { staticClass: "text-body" }, [
                                      _c("tr", { attrs: { role: "row" } }, [
                                        _c(
                                          "th",
                                          {
                                            staticClass: "sorting_asc",
                                            class:
                                              (this.$data.sortType == "asc" ||
                                                this.$data.sortType == "ASC") &&
                                              this.$data.sortBy == "id"
                                                ? "sorting_asc"
                                                : (this.$data.sortType ==
                                                    "desc" ||
                                                    this.$data.sortType ==
                                                      "DESC") &&
                                                  this.$data.sortBy == "id"
                                                ? "sorting_desc"
                                                : "sorting",
                                            staticStyle: { width: "31.25px" },
                                            attrs: {
                                              tabindex: "0",
                                              "aria-controls":
                                                "productUnitTable",
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-sort": "ascending",
                                              "aria-label":
                                                "ID: activate to sort column descending"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.sorting("id")
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                                ID\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "sorting",
                                            class:
                                              (this.$data.sortType == "asc" ||
                                                this.$data.sortType == "ASC") &&
                                              this.$data.sortBy == "first_name"
                                                ? "sorting_asc"
                                                : (this.$data.sortType ==
                                                    "desc" ||
                                                    this.$data.sortType ==
                                                      "DESC") &&
                                                  this.$data.sortBy ==
                                                    "first_name"
                                                ? "sorting_desc"
                                                : "sorting",
                                            staticStyle: { width: "95.5288px" },
                                            attrs: {
                                              tabindex: "0",
                                              "aria-controls":
                                                "productUnitTable",
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label":
                                                "First Name: activate to sort column ascending"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.sorting("first_name")
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                                NOmbre\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "sorting",
                                            class:
                                              (this.$data.sortType == "asc" ||
                                                this.$data.sortType == "ASC") &&
                                              this.$data.sortBy == "last_name"
                                                ? "sorting_asc"
                                                : (this.$data.sortType ==
                                                    "desc" ||
                                                    this.$data.sortType ==
                                                      "DESC") &&
                                                  this.$data.sortBy ==
                                                    "last_name"
                                                ? "sorting_desc"
                                                : "sorting",
                                            staticStyle: { width: "81.8109px" },
                                            attrs: {
                                              tabindex: "0",
                                              "aria-controls":
                                                "productUnitTable",
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label":
                                                "Last Name: activate to sort column ascending"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.sorting("last_name")
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                                Apellido\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "sorting",
                                            staticStyle: { width: "114.84px" },
                                            attrs: {
                                              tabindex: "0",
                                              "aria-controls":
                                                "productUnitTable",
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label":
                                                "Status: activate to sort column ascending"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                                Estado\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "sorting",
                                            staticStyle: { width: "158.462px" },
                                            attrs: {
                                              tabindex: "0",
                                              "aria-controls":
                                                "productUnitTable",
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label":
                                                "Is Seen: activate to sort column ascending"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                                Es visto\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.$parent.permissions.includes(
                                          "customer-manage"
                                        )
                                          ? _c(
                                              "th",
                                              {
                                                staticClass:
                                                  "no-sort sorting_disabled",
                                                staticStyle: {
                                                  width: "53.1891px"
                                                },
                                                attrs: {
                                                  rowspan: "1",
                                                  colspan: "1",
                                                  "aria-label": "Action"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                                Accion\n                                                            "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "tbody",
                                      {
                                        staticClass: "kt-table-tbody text-dark"
                                      },
                                      _vm._l(_vm.customers, function(customer) {
                                        return _c(
                                          "tr",
                                          {
                                            key: customer.id,
                                            staticClass:
                                              "kt-table-row kt-table-row-level-0 odd",
                                            attrs: { role: "row" }
                                          },
                                          [
                                            _c(
                                              "td",
                                              { staticClass: "sorting_1" },
                                              [
                                                _vm._v(
                                                  "\n                                                                " +
                                                    _vm._s(
                                                      customer.customer_id
                                                    ) +
                                                    "\n                                                            "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(
                                                    customer.customer_first_name
                                                  ) +
                                                  "\n                                                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(
                                                    customer.customer_last_name
                                                  ) +
                                                  "\n                                                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(
                                                    customer.customer_status ==
                                                      "1"
                                                      ? "Active"
                                                      : "Inactive"
                                                  ) +
                                                  "\n                                                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(
                                                    customer.is_seen == "0"
                                                      ? "unseen"
                                                      : "seen"
                                                  ) +
                                                  "\n                                                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _vm.$parent.permissions.includes(
                                              "customer-manage"
                                            )
                                              ? _c("td", [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        " click-edit",
                                                      attrs: {
                                                        href:
                                                          "javascript:void(0)",
                                                        id: "click-edit1",
                                                        "data-toggle":
                                                          "tooltip",
                                                        title: "",
                                                        "data-placement":
                                                          "right",
                                                        "data-original-title":
                                                          "Check out more demos"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.editcustomer(
                                                            customer
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-edit"
                                                      })
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.deletecustomer(
                                                            customer.customer_id
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-trash"
                                                      })
                                                    ]
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "pagination pagination-sm m-0 float-right"
                                  },
                                  [
                                    _c(
                                      "li",
                                      {
                                        class: [
                                          {
                                            disabled: !_vm.pagination
                                              .prev_page_url
                                          }
                                        ]
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "page-link",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                return _vm.fetchcustomers(
                                                  _vm.pagination.prev_page_url
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Previous")]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("li", { staticClass: "disabled" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "page-link text-dark",
                                          attrs: { href: "#" }
                                        },
                                        [
                                          _vm._v(
                                            "Page " +
                                              _vm._s(
                                                _vm.pagination.current_page
                                              ) +
                                              " of " +
                                              _vm._s(_vm.pagination.last_page)
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "page-item",
                                        class: [
                                          {
                                            disabled: !_vm.pagination
                                              .next_page_url
                                          }
                                        ]
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "page-link",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                return _vm.fetchcustomers(
                                                  _vm.pagination.next_page_url
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Next")]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    ])
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.display_form
      ? _c(
          "div",
          {
            staticClass:
              "offcanvas offcanvas-right kt-color-panel p-5 kt_notes_panel",
            class: _vm.display_form ? "offcanvas-on" : ""
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "offcanvas-header d-flex align-items-center justify-content-between pb-3"
              },
              [
                _c("h4", { staticClass: "font-size-h4 font-weight-bold m-0" }, [
                  _vm._v("Agregar Cliente")
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "btn btn-sm btn-icon btn-light btn-hover-primary kt_notes_panel_close",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        _vm.display_form = 0
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "bi bi-x",
                        attrs: {
                          width: "20px",
                          height: "20px",
                          viewBox: "0 0 16 16",
                          fill: "currentColor",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            d:
                              "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                          }
                        })
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("form", { attrs: { id: "myform" } }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "text-dark" }, [
                      _vm._v("Nombre ")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.customer.customer_first_name,
                          expression: "customer.customer_first_name"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", name: "text" },
                      domProps: { value: _vm.customer.customer_first_name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.customer,
                            "customer_first_name",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.has("first_name")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("name"))
                          }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "text-dark" }, [
                      _vm._v("Apellido ")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.customer.customer_last_name,
                          expression: "customer.customer_last_name"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", name: "text" },
                      domProps: { value: _vm.customer.customer_last_name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.customer,
                            "customer_last_name",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.has("last_name")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("last_name"))
                          }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "text-dark" }, [
                      _vm._v("Email ")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.customer.customer_email,
                          expression: "customer.customer_email"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "email", name: "text" },
                      domProps: { value: _vm.customer.customer_email },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.customer,
                            "customer_email",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.has("email")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("email"))
                          }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "text-dark" }, [
                      _vm._v("Password ")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.customer.password,
                          expression: "customer.password"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", name: "text" },
                      domProps: { value: _vm.customer.password },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.customer,
                            "password",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.has("password")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("password"))
                          }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("Estado")]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.customer.customer_status,
                            expression: "customer.customer_status"
                          }
                        ],
                        staticClass:
                          "js-example-basic-single js-states form-control bg-transparent",
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.customer,
                              "customer_status",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c(
                          "option",
                          {
                            attrs: { value: "1" },
                            domProps: {
                              selected: _vm.customer.customer_status == 1
                            }
                          },
                          [_vm._v("Active")]
                        ),
                        _vm._v(" "),
                        _c(
                          "option",
                          {
                            attrs: { value: "0" },
                            domProps: {
                              selected: _vm.customer.customer_status == 0
                            }
                          },
                          [_vm._v("In Active")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.errors.has("state_id")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("status"))
                          }
                        })
                      : _vm._e()
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.addcustomer()
                    }
                  }
                },
                [_vm._v("Guardar")]
              )
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-title mb-0" }, [
      _c("h3", { staticClass: "card-label mb-0 font-weight-bold text-body" }, [
        _vm._v(
          "\n                                            Clientes\n                                        "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }