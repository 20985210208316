var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0"
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("form", [
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("label", { staticClass: "text-body" }, [
                              _vm._v("Quotations")
                            ]),
                            _vm._v(" "),
                            _c("fieldset", { staticClass: "form-group mb-3" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedQuotation,
                                      expression: "selectedQuotation"
                                    }
                                  ],
                                  staticClass:
                                    " js-states form-control bg-transparent",
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.selectedQuotation = $event.target
                                          .multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      function($event) {
                                        return _vm.getQuotation()
                                      }
                                    ]
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("Select Quotation")
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.quotations, function(quotation) {
                                    return _c(
                                      "option",
                                      { domProps: { value: quotation.id } },
                                      [
                                        _vm._v(
                                          "\n                                                            " +
                                            _vm._s(quotation.id) +
                                            "\n                                                        "
                                        )
                                      ]
                                    )
                                  })
                                ],
                                2
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("label", { staticClass: "text-body" }, [
                              _vm._v("Warehouses")
                            ]),
                            _vm._v(" "),
                            _c("fieldset", { staticClass: "form-group mb-3" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.sale.warehouse_id,
                                      expression: "sale.warehouse_id"
                                    }
                                  ],
                                  staticClass:
                                    " js-states form-control bg-transparent",
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.sale,
                                        "warehouse_id",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("Select Warehouse")
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.warehouses, function(warehouse) {
                                    return _c(
                                      "option",
                                      {
                                        domProps: {
                                          value: warehouse.warehouse_id
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                                            " +
                                            _vm._s(warehouse.warehouse_name) +
                                            "\n                                                        "
                                        )
                                      ]
                                    )
                                  })
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _vm.errors.has("warehouse_id")
                                ? _c("small", {
                                    staticClass: "form-text text-danger",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.errors.get("warehouse_id")
                                      )
                                    }
                                  })
                                : _vm._e()
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("label", { staticClass: "text-body" }, [
                              _vm._v("Customer")
                            ]),
                            _vm._v(" "),
                            _c("fieldset", { staticClass: "form-group mb-3" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.sale.customer_id,
                                      expression: "sale.customer_id"
                                    }
                                  ],
                                  staticClass:
                                    " js-states form-control bg-transparent",
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.sale,
                                        "customer_id",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("Select Customer")
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.customers, function(customer) {
                                    return _c(
                                      "option",
                                      {
                                        domProps: {
                                          value: customer.customer_id
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                                            " +
                                            _vm._s(
                                              customer.customer_first_name
                                            ) +
                                            " " +
                                            _vm._s(
                                              customer.customer_last_name
                                            ) +
                                            "\n                                                        "
                                        )
                                      ]
                                    )
                                  })
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _vm.errors.has("customer_id")
                                ? _c("small", {
                                    staticClass: "form-text text-danger",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.errors.get("customer_id")
                                      )
                                    }
                                  })
                                : _vm._e()
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("label", { staticClass: "text-body" }, [
                              _vm._v("Sale Date")
                            ]),
                            _vm._v(" "),
                            _c("fieldset", { staticClass: "form-group mb-3" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.sale.sale_date,
                                    expression: "sale.sale_date"
                                  }
                                ],
                                staticClass: "form-control bg-white",
                                attrs: { type: "date", value: "" },
                                domProps: { value: _vm.sale.sale_date },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.sale,
                                      "sale_date",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _vm.errors.has("sale_date")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("sale_date")
                                    )
                                  }
                                })
                              : _vm._e()
                          ])
                        ])
                      ])
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0"
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("label", { staticClass: "text-body" }, [
                          _vm._v("Products")
                        ]),
                        _vm._v(" "),
                        _c(
                          "fieldset",
                          { staticClass: "form-group mb-3 d-flex" },
                          [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.product_id,
                                    expression: "product_id"
                                  }
                                ],
                                staticClass:
                                  "js-example-basic-single js-states form-control bg-transparent",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.product_id = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v("Select Product")
                                ]),
                                _vm._v(" "),
                                _vm._l(_vm.products, function(product) {
                                  return _c(
                                    "option",
                                    { domProps: { value: product.product_id } },
                                    [
                                      _vm._v(
                                        "\n                                                    " +
                                          _vm._s(
                                            product.detail == null
                                              ? ""
                                              : product.detail[0].title
                                          ) +
                                          "\n                                                "
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass:
                                  "btn-secondary btn ml-2 white pt-1 pb-1 d-flex align-items-center justify-content-center",
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function($event) {
                                    return _vm.addProduct()
                                  }
                                }
                              },
                              [_vm._v("ADD")]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.errors.has("product_id")
                          ? _c("small", {
                              staticClass: "form-text text-danger",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.errors.get("product_id")
                                )
                              }
                            })
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _vm.display_table
                        ? _c("div", { staticClass: "col-12" }, [
                            _c("div", { staticClass: "table-responsive" }, [
                              _c(
                                "table",
                                {
                                  staticClass: "table table-striped  text-body"
                                },
                                [
                                  _c("thead", [
                                    _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "border-0  header-heading",
                                          attrs: { scope: "col" }
                                        },
                                        [_vm._v("Name")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "border-0  header-heading",
                                          attrs: { scope: "col" }
                                        },
                                        [_vm._v("Quantity")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "border-0  header-heading",
                                          attrs: { scope: "col" }
                                        },
                                        [
                                          _vm._v(
                                            "Price " +
                                              _vm._s(_vm.getCurrencyTitle())
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "border-0  header-heading",
                                          attrs: { scope: "col" }
                                        },
                                        [
                                          _vm._v(
                                            "Amount " +
                                              _vm._s(_vm.getCurrencyTitle())
                                          )
                                        ]
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "tbody",
                                    [
                                      _vm._l(_vm.selectedProducts, function(
                                        selectedProduct,
                                        index
                                      ) {
                                        return _c("tr", {}, [
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(selectedProduct.title)
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: " text-center" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.sale.qty[index],
                                                    expression:
                                                      "sale.qty[index]"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  placeholder: "Enter Quantity"
                                                },
                                                domProps: {
                                                  value: _vm.sale.qty[index]
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.computeValues()
                                                  },
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.sale.qty,
                                                      index,
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(" "),
                                              _vm.errors.has("qty." + index)
                                                ? _c("small", {
                                                    staticClass:
                                                      "form-text text-danger",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.errors.get(
                                                          "qty." + index
                                                        )
                                                      )
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: " text-center" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.sale.price[index],
                                                    expression:
                                                      "sale.price[index]"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  placeholder: "Enter Price"
                                                },
                                                domProps: {
                                                  value: _vm.sale.price[index]
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.computeValues()
                                                  },
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.sale.price,
                                                      index,
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              }),
                                              _vm._v(" "),
                                              _vm.errors.has("price." + index)
                                                ? _c("small", {
                                                    staticClass:
                                                      "form-text text-danger",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.errors.get(
                                                          "price." + index
                                                        )
                                                      )
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: " text-center" },
                                            [
                                              _c("input", {
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  readonly: "",
                                                  placeholder: "Enter Price"
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.sale.price[index] &&
                                                    _vm.sale.qty[index] &&
                                                    !isNaN(
                                                      _vm.sale.price[index]
                                                    ) &&
                                                    !isNaN(_vm.sale.qty[index])
                                                      ? _vm.sale.price[index] *
                                                        _vm.sale.qty[index]
                                                      : 0
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.computeValues()
                                                  }
                                                }
                                              })
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "confirm-delete",
                                                  attrs: {
                                                    href: "#",
                                                    title: "Delete"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.removeItem(
                                                        index
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fas fa-trash-alt"
                                                  })
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            attrs: {
                                              type: "hidden",
                                              set: (_vm.sale.product_id[index] =
                                                selectedProduct.product_id)
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("input", {
                                            attrs: {
                                              type: "hidden",
                                              set: (_vm.sale.product_name[
                                                index
                                              ] = selectedProduct.title)
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("input", {
                                            attrs: {
                                              type: "hidden",
                                              set: (_vm.sale.product_combination_id[
                                                index
                                              ] =
                                                selectedProduct.product_combination_id)
                                            }
                                          })
                                        ])
                                      }),
                                      _vm._v(" "),
                                      _c("tr", [
                                        _c("td"),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm.errors.has("qty")
                                            ? _c("small", {
                                                staticClass:
                                                  "form-text text-danger",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.errors.get("qty")
                                                  )
                                                }
                                              })
                                            : _vm._e()
                                        ]),
                                        _vm._v(" "),
                                        _c("td", { attrs: { colspan: "3" } }, [
                                          _vm.errors.has("price")
                                            ? _c("small", {
                                                staticClass:
                                                  "form-text text-danger",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.errors.get("price")
                                                  )
                                                }
                                              })
                                            : _vm._e()
                                        ])
                                      ])
                                    ],
                                    2
                                  )
                                ]
                              )
                            ])
                          ])
                        : _vm._e()
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0"
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "row mb-3" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("label", [_vm._v("Note")]),
                          _vm._v(" "),
                          _c("fieldset", { staticClass: "form-group mb-3" }, [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sale.desc,
                                  expression: "sale.desc"
                                }
                              ],
                              staticClass: "form-control",
                              staticStyle: { height: "130px" },
                              attrs: {
                                id: "label-textarea",
                                rows: "6",
                                name: "notes",
                                placeholder: "Please add some note",
                                spellcheck: "false"
                              },
                              domProps: { value: _vm.sale.desc },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.sale,
                                    "desc",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _vm.errors.has("desc")
                            ? _c("small", {
                                staticClass: "form-text text-danger",
                                domProps: {
                                  textContent: _vm._s(_vm.errors.get("desc"))
                                }
                              })
                            : _vm._e()
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row justify-content-end" }, [
                        _c("div", { staticClass: "col-12 col-md-3" }, [
                          _c("div", [
                            _c(
                              "table",
                              { staticClass: "table right-table mb-0" },
                              [
                                _c("tbody", [
                                  _c(
                                    "tr",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-between"
                                    },
                                    [
                                      _c("th", { staticClass: "border-0" }, [
                                        _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "font-size-h5 mb-0 font-size-bold text-dark"
                                          },
                                          [
                                            _vm._v(
                                              "Subtotal " +
                                                _vm._s(_vm.getCurrencyTitle())
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "border-0 justify-content-end d-flex text-black-50 font-size-base"
                                        },
                                        [_vm._v(_vm._s(_vm.sale.subtotal))]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "tr",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-between"
                                    },
                                    [
                                      _c("th", { staticClass: "border-0" }, [
                                        _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "font-size-h5 mb-0 font-size-bold text-dark"
                                          },
                                          [
                                            _vm._v(
                                              "Total " +
                                                _vm._s(_vm.getCurrencyTitle())
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "border-0 justify-content-end d-flex text-black-50 font-size-base"
                                        },
                                        [_vm._v(_vm._s(_vm.sale.payable))]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "tr",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-between"
                                    },
                                    [
                                      _c("th", { staticClass: "border-0" }, [
                                        _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "font-size-h5 mb-0 font-size-bold text-dark"
                                          },
                                          [
                                            _vm._v(
                                              "Due Amount " +
                                                _vm._s(_vm.getCurrencyTitle())
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "border-0 justify-content-end d-flex text-black-50 font-size-base"
                                        },
                                        [_vm._v(_vm._s(_vm.sale.due_amount))]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "tr",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-between"
                                    },
                                    [
                                      _c("th", { staticClass: "border-0" }, [
                                        _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "font-size-h5 mb-0 font-size-bold text-dark"
                                          },
                                          [
                                            _vm._v(
                                              "Discount " +
                                                _vm._s(_vm.getCurrencyTitle())
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "border-0 justify-content-end d-flex text-black-50 font-size-base"
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.sale.discount,
                                                expression: "sale.discount"
                                              }
                                            ],
                                            staticClass:
                                              "form-control bg-white",
                                            attrs: { type: "text", value: "0" },
                                            domProps: {
                                              value: _vm.sale.discount
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.computeValues()
                                              },
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.sale,
                                                  "discount",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "tr",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-between"
                                    },
                                    [
                                      _c("th", { staticClass: "border-0" }, [
                                        _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "font-size-h5 mb-0 font-size-bold text-dark"
                                          },
                                          [
                                            _vm._v(
                                              "Amount Paid " +
                                                _vm._s(_vm.getCurrencyTitle())
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "border-0 justify-content-end d-flex text-black-50 font-size-base"
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.sale.amount_paid,
                                                expression: "sale.amount_paid"
                                              }
                                            ],
                                            staticClass:
                                              "form-control bg-white",
                                            attrs: { type: "text", value: "0" },
                                            domProps: {
                                              value: _vm.sale.amount_paid
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.computeValues()
                                              },
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.sale,
                                                  "amount_paid",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _vm._m(1),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "border-0 justify-content-end d-flex text-black-50 font-size-base"
                                      },
                                      [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.sale.tax_id,
                                                expression: "sale.tax_id"
                                              }
                                            ],
                                            staticClass:
                                              " js-states form-control bg-transparent",
                                            on: {
                                              change: function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.$set(
                                                  _vm.sale,
                                                  "tax_id",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "option",
                                              { attrs: { value: "" } },
                                              [_vm._v("Select Tax")]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(_vm.taxes, function(tax) {
                                              return _c(
                                                "option",
                                                {
                                                  domProps: {
                                                    value: tax.tax_id
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                                                        " +
                                                      _vm._s(tax.tax_title) +
                                                      "\n                                                                    "
                                                  )
                                                ]
                                              )
                                            })
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _vm.errors.has("tax_id")
                                          ? _c("small", {
                                              staticClass:
                                                "form-text text-danger",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.errors.get("tax_id")
                                                )
                                              }
                                            })
                                          : _vm._e()
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c("th", { staticClass: "border-0" }, [
                                      _c(
                                        "h5",
                                        {
                                          staticClass:
                                            "font-size-h5 mb-0 font-size-bold text-dark"
                                        },
                                        [
                                          _vm._v(
                                            "Tax Amount " +
                                              _vm._s(_vm.getCurrencyTitle())
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "border-0 justify-content-end d-flex text-black-50 font-size-base"
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.sale.tax_amount,
                                              expression: "sale.tax_amount"
                                            }
                                          ],
                                          staticClass: "form-control bg-white",
                                          attrs: { type: "text", value: "0" },
                                          domProps: {
                                            value: _vm.sale.tax_amount
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.computeValues()
                                            },
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.sale,
                                                "tax_amount",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  ])
                                ])
                              ]
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-12" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            on: {
                              click: function($event) {
                                return _vm.addsale()
                              }
                            }
                          },
                          [_vm._v("Submit")]
                        )
                      ])
                    ])
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
        _c(
          "div",
          {
            staticClass:
              "card card-custom gutter-b bg-transparent shadow-none border-0"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "card-header align-items-center  border-bottom-dark px-0"
              },
              [
                _c("div", { staticClass: "card-title mb-0" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "card-label mb-0 font-weight-bold text-body"
                    },
                    [
                      _vm._v(
                        "\n                                            Add Sale\n                                        "
                      )
                    ]
                  )
                ])
              ]
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "border-0" }, [
      _c("h5", { staticClass: "font-size-h5 mb-0 font-size-bold text-dark" }, [
        _vm._v("Tax")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }