var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "card card-custom gutter-b bg-white border-0" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "card-body", attrs: { id: "printableTable" } }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-6 col-12" }, [
            _c("h6", { staticClass: "text-body" }, [_vm._v("Seo Meta Tags")]),
            _vm._v(" "),
            _c("fieldset", { staticClass: "form-group mb-3" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.seo_meta_tag,
                    expression: "seo_meta_tag"
                  }
                ],
                staticClass: "form-control bg-transparent text-dark",
                attrs: { type: "text", placeholder: "Seo meta tags" },
                domProps: { value: _vm.seo_meta_tag },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.seo_meta_tag = $event.target.value
                    },
                    function($event) {
                      return _vm.setSeoMetaTag($event.target.value)
                    }
                  ]
                }
              }),
              _vm._v(" "),
              _vm.errors.has("seo_meta_tag")
                ? _c("small", {
                    staticClass: "form-text text-danger",
                    domProps: {
                      textContent: _vm._s(_vm.errors.get("seo_meta_tag"))
                    }
                  })
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-6 col-12" }, [
            _c("h6", { staticClass: "text-body" }, [_vm._v("Seo Description")]),
            _vm._v(" "),
            _c("fieldset", { staticClass: "form-group mb-3" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.seo_desc,
                    expression: "seo_desc"
                  }
                ],
                staticClass: "form-control bg-transparent text-dark",
                attrs: { type: "text", placeholder: "Seo Description" },
                domProps: { value: _vm.seo_desc },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.seo_desc = $event.target.value
                    },
                    function($event) {
                      return _vm.setSeoDesc($event.target.value)
                    }
                  ]
                }
              }),
              _vm._v(" "),
              _vm.errors.has("seo_desc")
                ? _c("small", {
                    staticClass: "form-text text-danger",
                    domProps: {
                      textContent: _vm._s(_vm.errors.get("seo_desc"))
                    }
                  })
                : _vm._e()
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 d-flex justify-content-end" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-dark swipe-to-top cta ",
            class: { active: _vm.isActive("ad-info-tab") },
            attrs: { href: "#", "data-toggle": "pill" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.setActive("ad-info-tab")
              }
            }
          },
          [_vm._v("Back")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn btn-primary  ml-2",
            attrs: { href: "#", "data-toggle": "pill" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.addProduct()
              }
            }
          },
          [_vm._v("Save")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header border-0 align-items-center" },
      [
        _c(
          "h3",
          { staticClass: "card-label mb-0 font-weight-bold text-body" },
          [_vm._v("\n        Seo\n      ")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }