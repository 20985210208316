var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group row" }, [
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Direccion de Factura")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.notification.invoice_address,
            expression: "notification.invoice_address"
          }
        ],
        ref: "invoice_address",
        staticClass: "form-control border-dark",
        attrs: { type: "text", placeholder: "" },
        domProps: { value: _vm.notification.invoice_address },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.notification, "invoice_address", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Correo Electronico de la Factura")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.notification.invoice_email,
            expression: "notification.invoice_email"
          }
        ],
        ref: "invoice_email",
        staticClass: "form-control border-dark",
        attrs: { type: "text", placeholder: "" },
        domProps: { value: _vm.notification.invoice_email },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.notification, "invoice_email", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Factura Movil")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.notification.invoice_mobile,
            expression: "notification.invoice_mobile"
          }
        ],
        ref: "invoice_mobile",
        staticClass: "form-control border-dark",
        attrs: { type: "text", placeholder: "" },
        domProps: { value: _vm.notification.invoice_mobile },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.notification, "invoice_mobile", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Telefono de Factura")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.notification.invoice_phone,
            expression: "notification.invoice_phone"
          }
        ],
        ref: "invoice_phone",
        staticClass: "form-control border-dark",
        attrs: { type: "text", placeholder: "" },
        domProps: { value: _vm.notification.invoice_phone },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.notification, "invoice_phone", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Logotipo de la Factura")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.notification.invoice_logo,
            expression: "notification.invoice_logo"
          }
        ],
        ref: "invoice_logo",
        staticClass: "form-control border-dark",
        attrs: { type: "text", placeholder: "" },
        domProps: { value: _vm.notification.invoice_logo },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.notification, "invoice_logo", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Prefijo de Factura")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.notification.invoice_prefix,
            expression: "notification.invoice_prefix"
          }
        ],
        ref: "invoice_prefix",
        staticClass: "form-control border-dark",
        attrs: { type: "text", placeholder: "" },
        domProps: { value: _vm.notification.invoice_prefix },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.notification, "invoice_prefix", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Contenido del pie de pagina de la Factura")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.notification.invoice_footer_content,
            expression: "notification.invoice_footer_content"
          }
        ],
        ref: "invoice_footer_content",
        staticClass: "form-control border-dark",
        attrs: { type: "text", placeholder: "" },
        domProps: { value: _vm.notification.invoice_footer_content },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(
              _vm.notification,
              "invoice_footer_content",
              $event.target.value
            )
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c("br"),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "submit" },
          on: {
            click: function($event) {
              return _vm.updateSetting()
            }
          }
        },
        [_vm._v("Guardar")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }