<template>
    <div>
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-12 col-xl-12">
                                <div class="card card-custom gutter-b bg-transparent shadow-none border-0">
                                    <div class="card-header align-items-center  border-bottom-dark px-0">
                                        <div class="card-title mb-0">
                                            <h3 class="card-label mb-0 font-weight-bold text-body">
                                            Listado Cotizaciones web
                                            </h3>
                                        </div>
                                        <div class="icons d-flex">
                                            <button class="btn ml-2 p-0 kt_notes_panel_toggle" data-toggle="tooltip" title="" data-placement="right" data-original-title="Check out more demos" v-if="$parent.permissions.includes('list-quotes')">
                                                <span class="bg-secondary h-30px font-size-h5 w-30px d-flex align-items-center justify-content-center  rounded-circle shadow-sm " v-on:click="
                                                            display_form = !display_form
                                                        ">
                                                    <svg width="25px" height="25px" viewBox="0 0 16 16" class="bi bi-plus white" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
                                                    </svg>
                                                </span>
                                            </button>
                                            <!-- <a href="#" onclick="printDiv()" class="ml-2">
                                                <span class="icon h-30px font-size-h5 w-30px d-flex align-items-center justify-content-center rounded-circle ">
                                                    <svg width="15px" height="15px" viewBox="0 0 16 16" class="bi bi-printer-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5z"></path>
                                                        <path fill-rule="evenodd" d="M11 9H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z"></path>
                                                        <path fill-rule="evenodd" d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"></path>
                                                    </svg>
                                                </span>
                                            </a>
                                            <a href="#" class="ml-2">
                                                <span class="icon h-30px font-size-h5 w-30px d-flex align-items-center justify-content-center rounded-circle ">
                                                    <svg width="15px" height="15px" viewBox="0 0 16 16" class="bi bi-file-earmark-text-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M2 2a2 2 0 0 1 2-2h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7 2l.5-2.5 3 3L10 5a1 1 0 0 1-1-1zM4.5 8a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5z"></path>
                                                    </svg>
                                                </span>
                                            </a> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 ">
                                <div class="card card-custom gutter-b bg-white border-0">
                                    <div class="card-body">
                                        <div>
                                            <div class=" table-responsive" id="printableTable">
                                            
                                                <div id="productaccountTable_wrapper" class="dataTables_wrapper no-footer">
    
                                                <div class="dataTables_length" id="productaccountTable_length"><label>Show 
                                                <select name="productaccountTable_length" aria-controls="productaccountTable" class="" v-model="limit" v-on:change="fetchaccounts()">
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="200">200</option>
                                                <option value="500">500</option>
                                                <option value="1000">1000</option>
                                                </select> entries</label></div>
    
                                                <div id="productaccountTable_filter" class="dataTables_filter"><label>Search:<input type="search" class="" placeholder="" aria-controls="productaccountTable" v-model="searchParameter" @keyup="fetchaccounts()"></label></div>
                                                    <table id="productaccountTable" class="display dataTable no-footer" role="grid">
                                                        <thead class="text-body">
                                                            <tr role="row">
                                                                <th class="sorting" tabindex="0" aria-controls="productaccountTable" rowspan="1" colspan="1" aria-sort="ascending" aria-label="ID: activate to sort column descending"  @click="sorting('id')" :class="(this.$data.sortType == 'asc' || this.$data.sortType == 'ASC') && this.$data.sortBy == 'id'  ? 'sorting_asc' : (this.$data.sortType == 'desc' || this.$data.sortType == 'DESC') && this.$data.sortBy == 'id' ? 'sorting_desc' : 'sorting'">
                                                                    N° 
                                                                </th>
                                                                <th class="sorting" tabindex="0" aria-controls="productaccountTable" rowspan="1" colspan="1" aria-label="account: activate to sort column ascending"  @click="sorting('nombre')" :class="(this.$data.sortType == 'asc' || this.$data.sortType == 'ASC') && this.$data.sortBy == 'name'  ? 'sorting_asc' : (this.$data.sortType == 'desc' || this.$data.sortType == 'DESC') && this.$data.sortBy == 'nombre' ? 'sorting_desc' : 'sorting'">
                                                               Correo Electronico 
                                                                </th>
                                                                <th class="sorting" tabindex="0" aria-controls="productaccountTable" rowspan="1" colspan="1" aria-label="account: activate to sort column ascending"  @click="sorting('nombre')" :class="(this.$data.sortType == 'asc' || this.$data.sortType == 'ASC') && this.$data.sortBy == 'name'  ? 'sorting_asc' : (this.$data.sortType == 'desc' || this.$data.sortType == 'DESC') && this.$data.sortBy == 'nombre' ? 'sorting_desc' : 'sorting'">
                                                               Origen 
                                                                </th>
                                                                <th class="no-sort sorting_disabled" rowspan="1" colspan="1">
                                                                  Destino
                                                                </th>
                                                                <th class="no-sort sorting_disabled" rowspan="1" colspan="1">
                                                                Peso (en Kg)
                                                                </th>
                                                                <th class="no-sort sorting_disabled" rowspan="1" colspan="1">
                                                                Dimensiones
                                                                </th>
                                                                <th class="no-sort sorting_disabled" rowspan="1" colspan="1">
                                                                Fecha Envio
                                                                </th>
                                                                
                                                                <th class="no-sort sorting_disabled" rowspan="1" colspan="1" aria-label="Action" >
                                                                    Accion
                                                                </th>
                                                               
                                                            </tr>
                                                        </thead>
                                                        <tbody class="kt-table-tbody text-dark">
                                                            <tr class="kt-table-row kt-table-row-level-0 odd" role="row" v-for="quation in quations" v-bind:key="quation.id">
                                                                <td class="sorting_1">
                                                                    00{{ quation.id}}
                                                                </td>
                                                                <td>
                                                                    {{ quation.email }}
                                                                </td>
                                                                <td>
                                                                    {{ quation.origin }}
                                                                </td>
                                                                <td>{{ quation.destination }}</td>
                                                                <td>
                                                                    {{ quation.weight }}
                                                                </td>
                                                                <td>
                                                                    {{ quation.dimensions }}
                                                                </td>
                                                                <td>
                                                                    {{ quation.date }}
                                                                </td>
                                                                <td v-if="quation.id > 0">
                                                                 <a class="dropdown-item" href="#" @click="deleteaccount(quation.id)"><i class="fa fa-trash"></i></a>
                                                                </td>
                                                             
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <ul class="pagination pagination-sm m-0 float-right">
                                                        <li v-bind:class="[{disabled: !pagination.prev_page_url}]"><a class="page-link" href="#" @click="fetchaccounts(pagination.prev_page_url)">Previous</a></li>
    
                                                        <li class="disabled"><a class="page-link text-dark" href="#">Page {{ pagination.current_page }} of {{ pagination.last_page }}</a></li>
    
                                                        <li v-bind:class="[{disabled: !pagination.next_page_url}]" class="page-item"><a class="page-link" href="#" @click="fetchaccounts(pagination.next_page_url)">Next</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    
    </div>
    
    </template>
<script>
import ErrorHandling from "../../ErrorHandling";
import axios from 'axios';
export default {
  
    data() {
        return {
            display_form: 0,
            id : null,
            viewToPrint: null,
            searchParameter: '',
            sortBy: 'id',
            sortType: 'ASC',
            limit: 10,
            error_message: '',
            edit: false,
            pagination: {},
            request_method: "",
            accounts: [],
            quations: [],
            guia_dropdowns:[],
            token: [],
            childToAppend:[],
            selected_account_id:"",
            errors: new ErrorHandling(),
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute("content")
        };
    },

    methods: {
        
        fetchaccounts(page_url) {
            this.$parent.loading = true;
            let vm = this;
            page_url = page_url || "/api/admin/cotizacion/list";
            axios.get(page_url, this.token).then(res => {
                console.log(res.data.data);
                this.quations = res.data.data;
                vm.makePagination(res.data.meta, res.data.links);
            }).finally(() => (this.$parent.loading = false));
        },

        makePagination(meta, links) {
            let pagination = {
                current_page: meta.current_page,
                last_page: meta.last_page,
                next_page_url: links.next,
                prev_page_url: links.prev
            };

            this.pagination = pagination;
        },
       
        deleteaccount(id) {
            if (confirm('Estas Seguro de Eliminar el Registro?')) {
                this.$parent.loading = true;
                axios.delete(`/api/admin/cotizacion/${id}/delete`,this.token)
                    .then(res => {
                        if (res.data.status == "Success") {
                            this.$toaster.success('Settings has been updated successfully')
                            this.fetchaccounts();
                        }

                    })
                    .catch(err => console.log(err))
                    .finally(() => (this.$parent.loading = false));
            }
        },
     
        sorting(sortBy){
            this.sortBy = sortBy;
            this.sortType = this.sortType == 'asc' || this.sortType == 'ASC' ? this.sortType='desc' : this.sortType = 'asc';
            this.fetchaccounts();
        },
         appendChild(e,select){
            this.findChild(e.target.value,select);
        },
        findChild(parent,select){
           for(var i = 0; i < this.childToAppend.length;i++){
                if(this.childToAppend[i].select == select){
                    this.childToAppend.splice(i);
                }
            }
                    
            axios.get("/api/admin/account?parent_id="+parent, this.token).then(res => {
               this.selected_account_id = parent;
                if(res.data.data.length > 0){
                    var data = res.data.data;
                    this.childToAppend.push({data,select});
                }
                
            });
            
        },
        agregarProducto(){
                this.guia.productos.push({ nbultos: this.nbultos, descripcion: this.descripcion, kilos: this.kilos, total: this.total, guia_id: this.guia_id, editando: false });
                this.nbultos = '';
                this.descripcion = '';
                this.kilos = '';
                this.total = '';
            }
        

    },
    mounted() {
        
        var token = localStorage.getItem('token');
        this.token = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        this.fetchaccounts();
       
    }
};
</script>    