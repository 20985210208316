import { render, staticRenderFns } from "./LoginSignup.vue?vue&type=template&id=a85666c0&"
import script from "./LoginSignup.vue?vue&type=script&lang=js&"
export * from "./LoginSignup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\laragon\\www\\sistematransporte\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a85666c0')) {
      api.createRecord('a85666c0', component.options)
    } else {
      api.reload('a85666c0', component.options)
    }
    module.hot.accept("./LoginSignup.vue?vue&type=template&id=a85666c0&", function () {
      api.rerender('a85666c0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/admin/settings/LoginSignup.vue"
export default component.exports