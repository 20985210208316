<template>
<div class="form-group row">
    <div class="col-md-6">
        <label>Estilo de encabezado</label>
        <fieldset class="form-group mb-3">
            <select class="js-example-basic-single js-states form-control bg-transparent" v-model='theme.header_style'>
                <option value="style1">Estilo 1</option>
                <option value="style2">Estilo 2</option>
                <option value="style3">Estilo 3</option>
                <option value="style4">Estilo 4</option>
                <option value="style5">Estilo 5</option>
                <option value="style6">Estilo 6</option>
                <option value="style7">Estilo 7</option>
                <option value="style8">Estilo 8</option>
            </select>

        </fieldset>
    </div>
    <div class="col-md-6">
        <label>Estilo deslizante</label>
        <fieldset class="form-group mb-3">
            <select class="js-example-basic-single js-states form-control bg-transparent" v-model='theme.slider_style'>
                <option value="style1">Estilo 1</option>
                <option value="style2">Estilo 2</option>
                <option value="style3">Estilo 3</option>
                <option value="style4">Estilo 4</option>
                <option value="style5">Estilo 5</option>
            </select>
        </fieldset>
    </div>
    <div class="col-md-6">
        <label>Banner</label>
        <fieldset class="form-group mb-3">
            <select class="js-example-basic-single js-states form-control bg-transparent" v-model='theme.banner_style'>
                <option value="style1">Estilo 1</option>
                <option value="style2">Estilo 2</option>
                <option value="style3">Estilo 3</option>
                <option value="style4">Estilo 4</option>
                <option value="style5">Estilo 5</option>
                <option value="style6">Estilo 6</option>
                <option value="style7">Estilo 7</option>
                <option value="style8">Estilo 8</option>
                <option value="style9">Estilo 9</option>
                <option value="style10">Estilo 10</option>
                <option value="style11">Estilo 11</option>
                <option value="style12">Estilo 12</option>
                <option value="style13">Estilo 13</option>
                <option value="style14">Estilo 14</option>
                <option value="style15">Estilo 15</option>
                <option value="style16">Estilo 16</option>
                <option value="style17">Estilo 17</option>
                <option value="style18">Estilo 18</option>
                <option value="style19">Estilo 19</option>
            </select>

        </fieldset>
    </div>
    <div class="col-md-6">
        <label>Pie de Pagina</label>
        <fieldset class="form-group mb-3">
            <select class="js-example-basic-single js-states form-control bg-transparent" v-model='theme.Footer_style'>
                <option value="style1">Estilo 1</option>
                <option value="style2">Estilo 2</option>
                <option value="style3">Estilo 3</option>
                <option value="style4">Estilo 4</option>
                <option value="style5">Estilo 5</option>
                <option value="style6">Estilo 6</option>
                <option value="style7">Estilo 7</option>
                <option value="style8">Estilo 8</option>
                <option value="style9">Estilo 9</option>
                <option value="style10">Estilo 10</option>
            </select>

        </fieldset>
    </div>
    <div class="col-md-6">
        <label>
Estilo de tarjeta</label>
        <fieldset class="form-group mb-3">
            <select class="js-example-basic-single js-states form-control bg-transparent" v-model='theme.card_style'>
                <option value="style1">Estilo 1</option>
                <option value="style2">Estilo 2</option>
                <option value="style3">Estilo 3</option>
                <option value="style4">Estilo 4</option>
                <option value="style5">Estilo 5</option>
                <option value="style6">Estilo 6</option>
                <option value="style7">Estilo 7</option>
                <option value="style8">Estilo 8</option>
                <option value="style9">Estilo 9</option>
                <option value="style10">Estilo 10</option>
                <option value="style11">Estilo 11</option>
                <option value="style12">Estilo 12</option>
                <option value="style13">Estilo 13</option>
                <option value="style14">Estilo 14</option>
                <option value="style15">Estilo 15</option>
                <option value="style16">Estilo 16</option>
                <option value="style17">Estilo 17</option>
                <option value="style18">Estilo 18</option>
                <option value="style19">Estilo 19</option>
                <option value="style20">Estilo 20</option>

            </select>

        </fieldset>
    </div>
    <div class="col-md-6">
        <label>Estilo de página de tarjeta</label>
        <fieldset class="form-group mb-3">
            <select class="js-example-basic-single js-states form-control bg-transparent" v-model='theme.cart_page'>
                <option value="style1">Estilo 1</option>
                <option value="style2">Estilo 2</option>
            </select>

        </fieldset>
    </div>
    <div class="col-md-6">
        <label>Sobre nosotros Estilo de página</label>
        <fieldset class="form-group mb-3">
            <select class="js-example-basic-single js-states form-control bg-transparent" v-model='theme.about_us'>
                <option value="style1">Estilo 1</option>
                <option value="style2">Estilo 2</option>
                <option value="style3">Estilo 3</option>
            </select>

        </fieldset>
    </div>

    <div class="col-md-6">
        <label>Estilo de página de blog</label>
        <fieldset class="form-group mb-3">
            <select class="js-example-basic-single js-states form-control bg-transparent" v-model='theme.blog'>
                <option value="style1">Estilo 1</option>
                <option value="style2">Estilo 2</option>
            </select>

        </fieldset>
    </div>
    <div class="col-md-6">
        <label>Contáctenos Estilo de página</label>
        <fieldset class="form-group mb-3">
            <select class="js-example-basic-single js-states form-control bg-transparent" v-model='theme.contact_us'>
                <option value="style1">Estilo 1</option>
                <option value="style2">Estilo 2</option>
            </select>

        </fieldset>
    </div>

    <div class="col-md-6">
        <label>
Estilo de página de inicio de sesión</label>
        <fieldset class="form-group mb-3">
            <select class="js-example-basic-single js-states form-control bg-transparent" v-model='theme.login'>
                <option value="style1">Estilo 1</option>
                <option value="style2">Estilo 2</option>
            </select>

        </fieldset>
    </div>

    <div class="col-md-6">
        <label>Estilo de página del producto</label>
        <fieldset class="form-group mb-3">
            <select class="js-example-basic-single js-states form-control bg-transparent" v-model='theme.product_detail'>
                <option value="style1">Estilo 1</option>
                <option value="style2">Estilo 2</option>
                <option value="style3">Estilo 3</option>
                <option value="style4">Estilo 4</option>
                <option value="style5">Estilo 5</option>
            </select>

        </fieldset>
    </div>

    <div class="col-md-6">
        <label>Estilo de página de la página de la tienda</label>
        <fieldset class="form-group mb-3">
            <select class="js-example-basic-single js-states form-control bg-transparent" v-model='theme.shop'>
                <option value="style1">Estilo 1</option>
                <option value="style2">Estilo 2</option>
                <option value="style3">Estilo 3</option>
                <option value="style4">Estilo 4</option>
                <option value="style5">Estilo 5</option>
            </select>

        </fieldset>
    </div>
    
    <div class="col-md-12">
        <button @click="updateSetting()" type="submit" class="btn btn-primary">Guardar</button>
    </div>

</div>
</div>
</template>

<script>
import ErrorHandling from './../../../ErrorHandling'
export default {
    data() {
        return {
            theme: {
                header_style: "style1",
                Footer_style: "style1",
                slider_style: "style1",
                banner_style: "style1",
                card_style: "style1",
                about_us: "style1",
                blog: "style1",
                cart_page: "style1",
                contact_us: "style1",
                login: "style1",
                product_detail: "style1",
                shop: "style1",
            },
            billers: [],
            warehouses: [],
            customers: [],
            errors: new ErrorHandling(),
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        };
    },

    methods: {
        
        fetchSetting() {
            this.$emit('updateLoadingState', true);
            var token = localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            var responseData = {};

            axios.get('/api/admin/setting?type=web_theme_setting', config)
                .then(res => {
                    for (var i = 0; i < res.data.data.length; i++) {
                        Object.assign(responseData, {
                            [res.data.data[i].setting_key]: res.data.data[i].setting_value
                        });
                    }
                    console.log('response datass', responseData);
                    this.theme = responseData;
                })
                .finally(() => (this.$emit('updateLoadingState', false)));
        },

        updateSetting() {
            this.$emit('updateLoadingState', true);
            var theme = Object.entries(this.theme);
            var key = [];
            var value = [];

            for (var i = 0; i < theme.length; i++) {
                key.push(theme[i][0]);
                value.push(theme[i][1].toString())
            }

            console.log(key, value);

            var token = localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };

            axios.post('/api/admin/setting/web_theme_setting', {
                    _method: 'PUT',
                    key,
                    value
                }, config)
                .then(res => {
                    if (res.data.status == "Success") {
                        this.$toaster.success('Settings has been updated successfully')
                    } else if (res.data.status == 'Error') {
                        this.$toaster.error(res.data.message)
                    }

                })
                .catch(err => {
                    if (err.response.data.status == 'Error') {
                        this.$toaster.error(err.response.data.message)
                    }
                })
                .finally(() => (this.$emit('updateLoadingState', false)));

        }
    },
    mounted() {
        this.fetchSetting();
    }
};
</script>
