var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "aside aside-left aside-fixed d-flex flex-column flex-row-auto",
        class: _vm.burgerMenu ? "aside-on" : "",
        attrs: { id: "tc_aside" }
      },
      [
        _c(
          "div",
          { staticClass: "brand flex-column-auto", attrs: { id: "tc_brand" } },
          [
            _c(
              "router-link",
              { staticClass: "brand-logo", attrs: { to: "/admin/dashboard" } },
              [
                _c("img", {
                  staticClass: "brand-image",
                  staticStyle: { height: "25px" },
                  attrs: { alt: "Logo", src: "/assets/images/misc/favicon.png" }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "brand-text" }, [
                  _c("img", {
                    staticStyle: { height: "110px" },
                    attrs: {
                      alt: "Logo",
                      src: "/assets/images/misc/logodashboard.png"
                    }
                  })
                ])
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "aside-menu-wrapper flex-column-fluid overflow-auto h-100",
            attrs: { id: "tc_aside_menu_wrapper" }
          },
          [
            _c(
              "div",
              {
                staticClass: "aside-menu mb-5",
                attrs: {
                  id: "tc_aside_menu",
                  "data-menu-vertical": "1",
                  "data-menu-scroll": "1",
                  "data-menu-dropdown-timeout": "500"
                }
              },
              [
                _c("div", { attrs: { id: "accordion" } }, [
                  _c("ul", { staticClass: "nav flex-column" }, [
                    _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: { to: "/admin/dashboard" }
                          },
                          [
                            _c("span", { staticClass: "svg-icon nav-icon" }, [
                              _c(
                                "svg",
                                {
                                  staticClass: "feather feather-home",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "20px",
                                    height: "20px",
                                    viewBox: "0 0 24 24",
                                    fill: "none",
                                    stroke: "currentColor",
                                    "stroke-width": "2",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("polyline", {
                                    attrs: { points: "9 22 9 12 15 12 15 22" }
                                  })
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "nav-text" }, [
                              _vm._v(" Dashboard ")
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.$parent.permissions.includes("workers")
                      ? _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive("workers") },
                              attrs: {
                                "data-toggle": "collapse",
                                href: "javascript:void(0)",
                                "data-target": "#workers",
                                role: "button",
                                "aria-expanded": "false",
                                "aria-controls": "workers"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.setActive("workers")
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "svg-icon nav-icon" }, [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "feather feather-image",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      width: "20px",
                                      height: "20px",
                                      viewBox: "0 0 24 24",
                                      fill: "none",
                                      stroke: "currentColor",
                                      "stroke-width": "2",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round"
                                    }
                                  },
                                  [
                                    _c("rect", {
                                      attrs: {
                                        x: "3",
                                        y: "3",
                                        width: "18",
                                        height: "18",
                                        rx: "2",
                                        ry: "2"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("circle", {
                                      attrs: { cx: "8.5", cy: "8.5", r: "1.5" }
                                    }),
                                    _vm._v(" "),
                                    _c("polyline", {
                                      attrs: { points: "21 15 16 10 5 21" }
                                    })
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Choferes")
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "fas fa-chevron-right fa-rotate-90"
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "nav-collapse",
                              class: {
                                "collapse nav-collapse": !_vm.isActive(
                                  "workers"
                                )
                              },
                              attrs: {
                                id: "workers",
                                "data-parent": "#accordion"
                              }
                            },
                            [
                              _c("ul", { staticClass: "nav flex-column" }, [
                                _vm.$parent.permissions.includes("add-workers")
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: { to: "/admin/add-drivers" }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          "
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16"
                                                    }
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d:
                                                          "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Agregar Nuevo Chofer")]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$parent.permissions.includes("list-workers")
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: { to: "/admin/list-drivers" }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          "
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16"
                                                    }
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d:
                                                          "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Lista de Choferes")]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ])
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$parent.permissions.includes("guias")
                      ? _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive("guias") },
                              attrs: {
                                "data-toggle": "collapse",
                                href: "javascript:void(0)",
                                "data-target": "#guias",
                                role: "button",
                                "aria-expanded": "false",
                                "aria-controls": "guias"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.setActive("guias")
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "svg-icon nav-icon" }, [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "feather feather-image",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      width: "20px",
                                      height: "20px",
                                      viewBox: "0 0 24 24",
                                      fill: "none",
                                      stroke: "currentColor",
                                      "stroke-width": "2",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round"
                                    }
                                  },
                                  [
                                    _c("rect", {
                                      attrs: {
                                        x: "3",
                                        y: "3",
                                        width: "18",
                                        height: "18",
                                        rx: "2",
                                        ry: "2"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("circle", {
                                      attrs: { cx: "8.5", cy: "8.5", r: "1.5" }
                                    }),
                                    _vm._v(" "),
                                    _c("polyline", {
                                      attrs: { points: "21 15 16 10 5 21" }
                                    })
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Guias")
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "fas fa-chevron-right fa-rotate-90"
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "nav-collapse",
                              class: {
                                "collapse nav-collapse": !_vm.isActive("guias")
                              },
                              attrs: {
                                id: "guias",
                                "data-parent": "#accordion"
                              }
                            },
                            [
                              _c("ul", { staticClass: "nav flex-column" }, [
                                _vm.$parent.permissions.includes("add-guias")
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: { to: "/admin/add-guias" }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          "
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16"
                                                    }
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d:
                                                          "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Agregar Nueva Guia")]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$parent.permissions.includes("list-guias")
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: { to: "/admin/list-guias" }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          "
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16"
                                                    }
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d:
                                                          "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Lista de Guias")]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$parent.permissions.includes("add-recono")
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: { to: "/admin/add-recono" }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          "
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16"
                                                    }
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d:
                                                          "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [
                                                _vm._v(
                                                  "Agregar Conocimiento de Carga"
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$parent.permissions.includes("list-recono")
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: { to: "/admin/list-recono" }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          "
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16"
                                                    }
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d:
                                                          "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [
                                                _vm._v(
                                                  "Listado de Conocimiento de Carga"
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ])
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$parent.permissions.includes("factura")
                      ? _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive("factura") },
                              attrs: {
                                "data-toggle": "collapse",
                                href: "#catalogPurchase"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.setActive("factura")
                                }
                              }
                            },
                            [
                              _vm._m(0),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Factura")
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "fas fa-chevron-right fa-rotate-90"
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "nav-collapse",
                              class: {
                                "collapse nav-collapse": !_vm.isActive(
                                  "factura"
                                )
                              },
                              attrs: {
                                id: "catalogPurchase",
                                "data-parent": "#accordion"
                              }
                            },
                            [
                              _c("ul", { staticClass: "nav flex-column" }, [
                                _vm.$parent.permissions.includes("list-factura")
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: { to: "/admin/list-factura" }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          "
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16"
                                                    }
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d:
                                                          "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Listado de Facturas ")]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$parent.permissions.includes("add-factura")
                                  ? _c(
                                      "li",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link sub-nav-link",
                                            attrs: { to: "/admin/add-factura" }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          "
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "bi bi-circle",
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "10px",
                                                      height: "10px",
                                                      fill: "currentColor",
                                                      viewBox: "0 0 16 16"
                                                    }
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        "fill-rule": "evenodd",
                                                        d:
                                                          "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "nav-text" },
                                              [_vm._v("Agregar Factura")]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ])
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$parent.permissions.includes("quotes")
                      ? _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive("quotes") },
                              attrs: {
                                "data-toggle": "collapse",
                                href: "javascript:void(0)",
                                "data-target": "#quotes",
                                role: "button",
                                "aria-expanded": "false",
                                "aria-controls": "quotes"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.setActive("quotes")
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "svg-icon nav-icon" }, [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "feather feather-image",
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      width: "20px",
                                      height: "20px",
                                      viewBox: "0 0 24 24",
                                      fill: "none",
                                      stroke: "currentColor",
                                      "stroke-width": "2",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round"
                                    }
                                  },
                                  [
                                    _c("rect", {
                                      attrs: {
                                        x: "3",
                                        y: "3",
                                        width: "18",
                                        height: "18",
                                        rx: "2",
                                        ry: "2"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("circle", {
                                      attrs: { cx: "8.5", cy: "8.5", r: "1.5" }
                                    }),
                                    _vm._v(" "),
                                    _c("polyline", {
                                      attrs: { points: "21 15 16 10 5 21" }
                                    })
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Reportes")
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "fas fa-chevron-right fa-rotate-90"
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "nav-collapse",
                              class: {
                                "collapse nav-collapse": !_vm.isActive("quotes")
                              },
                              attrs: {
                                id: "Quotes",
                                "data-parent": "#accordion"
                              }
                            },
                            [
                              _c("ul", { staticClass: "nav flex-column" }, [
                                _vm.$parent.permissions.includes("report-guias")
                                  ? _c("li", { staticClass: "nav-item" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "nav-link sub-nav-link",
                                          attrs: { href: "/admin/report-guias" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          "
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "bi bi-circle",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    width: "10px",
                                                    height: "10px",
                                                    fill: "currentColor",
                                                    viewBox: "0 0 16 16"
                                                  }
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      "fill-rule": "evenodd",
                                                      d:
                                                        "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "nav-text" },
                                            [_vm._v("Reporte de Guias")]
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$parent.permissions.includes("report-carga")
                                  ? _c("li", { staticClass: "nav-item" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "nav-link sub-nav-link",
                                          attrs: { href: "/admin/report-carga" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          "
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "bi bi-circle",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    width: "10px",
                                                    height: "10px",
                                                    fill: "currentColor",
                                                    viewBox: "0 0 16 16"
                                                  }
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      "fill-rule": "evenodd",
                                                      d:
                                                        "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "nav-text" },
                                            [_vm._v("Reporte de Carga")]
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$parent.permissions.includes("report-ruta")
                                  ? _c("li", { staticClass: "nav-item" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "nav-link sub-nav-link",
                                          attrs: { href: "/admin/report-ruta" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "\n                            svg-icon\n                            nav-icon\n                            d-flex\n                            justify-content-center\n                          "
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "bi bi-circle",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    width: "10px",
                                                    height: "10px",
                                                    fill: "currentColor",
                                                    viewBox: "0 0 16 16"
                                                  }
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      "fill-rule": "evenodd",
                                                      d:
                                                        "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "nav-text" },
                                            [_vm._v("Rutas")]
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$parent.permissions.includes("people")
                      ? _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.isActive("people") },
                              attrs: {
                                "data-toggle": "collapse",
                                href: "#People"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.setActive("people")
                                }
                              }
                            },
                            [
                              _vm._m(1),
                              _vm._v(" "),
                              _c("span", { staticClass: "nav-text" }, [
                                _vm._v("Personas")
                              ]),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "fas fa-chevron-right fa-rotate-90"
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "nav-collapse",
                              class: {
                                "collapse nav-collapse": !_vm.isActive("people")
                              },
                              attrs: {
                                id: "People",
                                "data-parent": "#accordion"
                              }
                            },
                            [
                              _c("div", { attrs: { id: "accordion2" } }, [
                                _c("ul", { staticClass: "nav flex-column" }, [
                                  _vm.$parent.permissions.includes("role-list")
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: { to: "/admin/roles" }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                              svg-icon\n                              nav-icon\n                              d-flex\n                              justify-content-center\n                            "
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16"
                                                      }
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d:
                                                            "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Roles")]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes(
                                    "customer-list"
                                  )
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: { to: "/admin/customer" }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                              svg-icon\n                              nav-icon\n                              d-flex\n                              justify-content-center\n                            "
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16"
                                                      }
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d:
                                                            "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Clientes")]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$parent.permissions.includes("user-list")
                                    ? _c(
                                        "li",
                                        { staticClass: "nav-item" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "nav-link sub-nav-link",
                                              attrs: { to: "/admin/users" }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "\n                              svg-icon\n                              nav-icon\n                              d-flex\n                              justify-content-center\n                            "
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "bi bi-circle",
                                                      attrs: {
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        width: "10px",
                                                        height: "10px",
                                                        fill: "currentColor",
                                                        viewBox: "0 0 16 16"
                                                      }
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          d:
                                                            "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "nav-text" },
                                                [_vm._v("Usuarios")]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ])
                              ])
                            ]
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("br")
                ])
              ]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "svg-icon nav-icon" }, [
      _c("i", { staticClass: "fas fa-money-check-alt font-size-h4" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "svg-icon nav-icon" }, [
      _c("i", { staticClass: "fas fa-user-friends font-size-h4" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }