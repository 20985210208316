var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 col-lg-12" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 col-md-5" }, [
                    _c("div", { staticClass: "heading" }, [
                      _c("h2", [
                        _c("small", [
                          _vm._v("\n                        Order ID#"),
                          _c("span", { staticClass: "order-no" }, [
                            _vm._v(_vm._s(_vm.orders.order_id))
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("hr")
                    ]),
                    _vm._v(" "),
                    _c("table", { staticClass: "table order-id" }, [
                      _c("tbody", [
                        _c("tr", { staticClass: "d-flex" }, [
                          _c("td", { staticClass: "col-6 col-md-6" }, [
                            _vm._v("Order Status")
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "col-6 col-md-6" }, [
                            _vm.order_status == "Pending"
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.order_status,
                                        expression: "order_status"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.order_status = $event.target
                                            .multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        },
                                        function($event) {
                                          return _vm.statusUpdate()
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        attrs: { value: "Pending" },
                                        domProps: {
                                          selected:
                                            _vm.order_status == "Pending"
                                              ? "selected"
                                              : ""
                                        }
                                      },
                                      [_vm._v("Pending")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "option",
                                      {
                                        attrs: { value: "Complete" },
                                        domProps: {
                                          selected:
                                            _vm.order_status == "Complete"
                                              ? "selected"
                                              : ""
                                        }
                                      },
                                      [_vm._v("Complete")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "option",
                                      {
                                        attrs: { value: "Return" },
                                        domProps: {
                                          selected:
                                            _vm.order_status == "Return"
                                              ? "selected"
                                              : ""
                                        }
                                      },
                                      [_vm._v("Return")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "option",
                                      {
                                        attrs: { value: "Cancel" },
                                        domProps: {
                                          selected:
                                            _vm.order_status == "Cancel"
                                              ? "selected"
                                              : ""
                                        }
                                      },
                                      [_vm._v("Cancel")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.order_status == "Complete"
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.order_status,
                                        expression: "order_status"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.order_status = $event.target
                                            .multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        },
                                        function($event) {
                                          return _vm.statusUpdate()
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        attrs: { value: "Complete" },
                                        domProps: {
                                          selected:
                                            _vm.order_status == "Complete"
                                              ? "selected"
                                              : ""
                                        }
                                      },
                                      [_vm._v("Complete")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "option",
                                      {
                                        attrs: { value: "Return" },
                                        domProps: {
                                          selected:
                                            _vm.order_status == "Return"
                                              ? "selected"
                                              : ""
                                        }
                                      },
                                      [_vm._v("Return")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.order_status == "Cancel"
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.order_status,
                                        expression: "order_status"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.order_status = $event.target
                                            .multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        },
                                        function($event) {
                                          return _vm.statusUpdate()
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        attrs: { value: "Complete" },
                                        domProps: {
                                          selected:
                                            _vm.order_status == "Cancel"
                                              ? "selected"
                                              : ""
                                        }
                                      },
                                      [_vm._v("Cancel")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.order_status == "Return"
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.order_status,
                                        expression: "order_status"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.order_status = $event.target
                                            .multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        },
                                        function($event) {
                                          return _vm.statusUpdate()
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        attrs: { value: "Return" },
                                        domProps: {
                                          selected:
                                            _vm.order_status == "Return"
                                              ? "selected"
                                              : ""
                                        }
                                      },
                                      [_vm._v("Return")]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ]),
                        _vm._v(" "),
                        _c("tr", { staticClass: "d-flex" }, [
                          _c("td", { staticClass: "col-6 col-md-6" }, [
                            _vm._v("Order Date")
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass:
                                "underline col-6 col-md-6 order-date",
                              attrs: { align: "left" }
                            },
                            [
                              _vm._v(
                                "\n                          " +
                                  _vm._s(_vm.orders.order_date) +
                                  "\n                        "
                              )
                            ]
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-md-7" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("table", { staticClass: "table order-id" }, [
                      _c("tbody", [
                        _c("tr", { staticClass: "d-flex" }, [
                          _c("th", [
                            _vm._v(
                              "\n                          Shipping Cost : \n                        "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.orders.shipping_cost == null
                                  ? 0
                                  : _vm.orders.shipping_cost
                              )
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("tr", { staticClass: "d-flex" }, [
                          _c("th", [_vm._v("Shipping Method")]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                           " +
                                _vm._s(
                                  _vm.orders.shipping_method == null
                                    ? 0
                                    : _vm.orders.shipping_method
                                ) +
                                "\n                        "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("tr", { staticClass: "d-flex" }, [
                          _c("th", [_vm._v("Shipping Duration: ")]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                              " +
                                _vm._s(
                                  _vm.orders.shipping_duration == null
                                    ? 0
                                    : _vm.orders.shipping_duration
                                ) +
                                "\n                          "
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 col-md-5" }, [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("table", { staticClass: "table order-id" }, [
                      _c("tbody", [
                        _c("tr", { staticClass: "d-flex" }, [
                          _c("th", [_vm._v("Billing Address")]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                         " +
                                _vm._s(_vm.orders.billing_street_aadress) +
                                "\n                        "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("tr", { staticClass: "d-flex" }, [
                          _c("th", [_vm._v("Billing City")]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                         " +
                                _vm._s(_vm.orders.billing_city) +
                                "\n                        "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("tr", { staticClass: "d-flex" }, [
                          _c("th", [_vm._v("Billing Phone")]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                         " +
                                _vm._s(_vm.orders.billing_phone) +
                                "\n                        "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("tr", { staticClass: "d-flex" }, [
                          _c("th", [_vm._v("Billing Name")]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                         " +
                                _vm._s(
                                  _vm.orders.billing_first_name +
                                    " " +
                                    _vm.orders.billing_last_name
                                ) +
                                "\n                        "
                            )
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-md-7" }, [
                    _vm._m(3),
                    _vm._v(" "),
                    _c("table", { staticClass: "table order-id" }, [
                      _c("tbody", [
                        _c("tr", { staticClass: "d-flex" }, [
                          _c("td", [_vm._v("Payment Method")]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                          " +
                                _vm._s(_vm.orders.payment_method) +
                                "\n                        "
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("table", { staticClass: "table items" }, [
                  _vm._m(4),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    { attrs: { id: "order-show-detail" } },
                    [
                      _vm._l(_vm.orders.order_detail, function(detail) {
                        return detail.product.product_type == "variable"
                          ? _c("tr", { staticClass: "d-flex responsive-lay" }, [
                              _c("td", { staticClass: "col-12 col-md-2" }, [
                                _c("img", {
                                  staticClass: "img-fluid order-image",
                                  staticStyle: {
                                    width: "50px",
                                    height: "60px"
                                  },
                                  attrs: {
                                    src:
                                      "/gallary/" +
                                      detail.product_combination.gallary
                                        .gallary_name,
                                    alt:
                                      "/gallary/" +
                                      detail.product_combination.gallary
                                        .gallary_name
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "col-12 col-md-3 item-detail-left"
                                },
                                [
                                  _c("div", { staticClass: "text-body" }, [
                                    _c(
                                      "h4",
                                      { staticClass: "order-product-name" },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              detail.product.detail[0].title
                                            ) +
                                            " "
                                        ),
                                        _vm._l(
                                          detail.product_combination
                                            .combination,
                                          function(variation) {
                                            return _c("span", [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    variation.variation
                                                      .detail[0].name
                                                  ) +
                                                  "\n                            "
                                              )
                                            ])
                                          }
                                        )
                                      ],
                                      2
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "tag-color col-12 col-md-3 order-price"
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(detail.product_price) +
                                      "\n                    "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("td", { staticClass: "col-12 col-md-2" }, [
                                _c("div", { staticClass: "input-group" }, [
                                  _vm._v(
                                    "\n                       " +
                                      _vm._s(detail.product_qty) +
                                      "\n                      "
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "tag-s col-12 col-md-2 order-sub-price"
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(detail.product_total) +
                                      "\n                    "
                                  )
                                ]
                              )
                            ])
                          : _vm._e()
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.orders.order_detail, function(detail) {
                        return detail.product.product_type == "simple"
                          ? _c("tr", { staticClass: "d-flex responsive-lay" }, [
                              _c("td", { staticClass: "col-12 col-md-2" }, [
                                _c("img", {
                                  staticClass: "img-fluid order-image",
                                  staticStyle: {
                                    width: "50px",
                                    height: "60px"
                                  },
                                  attrs: {
                                    src:
                                      "/gallary/" +
                                      detail.product.product_gallary
                                        .gallary_name,
                                    alt:
                                      "/gallary/" +
                                      detail.product.product_gallary
                                        .gallary_name
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "col-12 col-md-3 item-detail-left"
                                },
                                [
                                  _c("div", { staticClass: "text-body" }, [
                                    _c(
                                      "h4",
                                      { staticClass: "order-product-name" },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              detail.product.detail[0].title
                                            ) +
                                            " \n                            "
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "tag-color col-12 col-md-3 order-price"
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(detail.product_price) +
                                      "\n                    "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("td", { staticClass: "col-12 col-md-2" }, [
                                _c("div", { staticClass: "input-group" }, [
                                  _vm._v(
                                    "\n                       " +
                                      _vm._s(detail.product_qty) +
                                      "\n                      "
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "tag-s col-12 col-md-2 order-sub-price"
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(detail.product_total) +
                                      "\n                    "
                                  )
                                ]
                              )
                            ])
                          : _vm._e()
                      })
                    ],
                    2
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 col-sm-12" }, [
                    _c("div", { staticClass: "comments-area" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          { attrs: { for: "exampleFormControlTextarea1" } },
                          [_vm._v("Comments")]
                        ),
                        _vm._v(
                          "\n                     " +
                            _vm._s(_vm.orders.order_notes) +
                            "\n                    "
                        )
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
        _c(
          "div",
          {
            staticClass:
              "\n                  card card-custom\n                  gutter-b\n                  bg-transparent\n                  shadow-none\n                  border-0\n                "
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "\n                    card-header\n                    align-items-center\n                    border-bottom-dark\n                    px-0\n                  "
              },
              [
                _c("div", { staticClass: "card-title mb-0" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "card-label mb-0 font-weight-bold text-body"
                    },
                    [
                      _vm._v(
                        "\n                      Order Detail\n                    "
                      )
                    ]
                  )
                ])
              ]
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading" }, [
      _c("h2", [_c("small", [_vm._v(" Shipping Details ")])]),
      _vm._v(" "),
      _c("hr")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading" }, [
      _c("h2", [_c("small", [_vm._v(" Billing Details ")])]),
      _vm._v(" "),
      _c("hr")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "heading" }, [
      _c("h2", [_c("small", [_vm._v(" Payment ")])]),
      _vm._v(" "),
      _c("hr")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "d-flex" }, [
        _c("th", { staticClass: "col-2" }),
        _vm._v(" "),
        _c("th", { staticClass: "col-3" }, [_vm._v('ITEM"S')]),
        _vm._v(" "),
        _c("th", { staticClass: "col-3" }, [_vm._v("PRICE")]),
        _vm._v(" "),
        _c("th", { staticClass: "col-2" }, [_vm._v("QTY")]),
        _vm._v(" "),
        _c("th", { staticClass: "col-2" }, [_vm._v("SUBTOTAL")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }