var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group row" }, [
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Motor de Correo ")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.emailsetting.mail_engine,
              expression: "emailsetting.mail_engine"
            }
          ],
          ref: "mail_engine",
          staticClass: "form-control border-dark",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.emailsetting.mail_engine },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.emailsetting, "mail_engine", $event.target.value)
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("SMTP Host")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.emailsetting.smtp_host,
            expression: "emailsetting.smtp_host"
          }
        ],
        ref: "smtp_host",
        staticClass: "form-control border-dark",
        attrs: { type: "text", placeholder: "" },
        domProps: { value: _vm.emailsetting.smtp_host },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.emailsetting, "smtp_host", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("SMTP Port")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.emailsetting.smtp_port,
            expression: "emailsetting.smtp_port"
          }
        ],
        ref: "smtp_port",
        staticClass: "form-control border-dark",
        attrs: { type: "text", placeholder: "" },
        domProps: { value: _vm.emailsetting.smtp_port },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.emailsetting, "smtp_port", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("SMTP Cifrado")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.emailsetting.smtp_encription,
            expression: "emailsetting.smtp_encription"
          }
        ],
        ref: "smtp_encription",
        staticClass: "form-control border-dark",
        attrs: { type: "text", placeholder: "" },
        domProps: { value: _vm.emailsetting.smtp_encription },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.emailsetting, "smtp_encription", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("SMTP Nombre de Usuario")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.emailsetting.smtp_username,
            expression: "emailsetting.smtp_username"
          }
        ],
        ref: "smtp_username",
        staticClass: "form-control border-dark",
        attrs: { type: "text", placeholder: "" },
        domProps: { value: _vm.emailsetting.smtp_username },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.emailsetting, "smtp_username", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("SMTP Contraseña")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.emailsetting.smtp_password,
            expression: "emailsetting.smtp_password"
          }
        ],
        ref: "smtp_password",
        staticClass: "form-control border-dark",
        attrs: { type: "text", placeholder: "" },
        domProps: { value: _vm.emailsetting.smtp_password },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.emailsetting, "smtp_password", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("SMTP de Correo Electronico")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.emailsetting.smtp_from_email,
            expression: "emailsetting.smtp_from_email"
          }
        ],
        ref: "smtp_from_email",
        staticClass: "form-control border-dark",
        attrs: { type: "email", placeholder: "" },
        domProps: { value: _vm.emailsetting.smtp_from_email },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.emailsetting, "smtp_from_email", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("SMTP de Nombre")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.emailsetting.smtp_from_name,
            expression: "emailsetting.smtp_from_name"
          }
        ],
        ref: "smtp_from_name",
        staticClass: "form-control border-dark",
        attrs: { type: "email", placeholder: "" },
        domProps: { value: _vm.emailsetting.smtp_from_name },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.emailsetting, "smtp_from_name", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("SMTP Estado")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.emailsetting.smtp_status,
            expression: "emailsetting.smtp_status"
          }
        ],
        ref: "smtp_status",
        staticClass: "form-control border-dark",
        attrs: { type: "email", placeholder: "" },
        domProps: { value: _vm.emailsetting.smtp_status },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.emailsetting, "smtp_status", $event.target.value)
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c("br"),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "submit" },
          on: {
            click: function($event) {
              return _vm.updateSetting()
            }
          }
        },
        [_vm._v("Guardar")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }