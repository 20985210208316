
<template>
    <div>
        <div class="d-flex flex-column-fluid">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-12 col-xl-12">
                                <div class="card card-custom gutter-b bg-transparent shadow-none border-0">
                                    <div class="card-header align-items-center  border-bottom-dark px-0">
                                        <div class="card-title mb-0">
                                            <h3 class="card-label mb-0 font-weight-bold text-body">
                                            Rutas de Choferes
                                            </h3>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 ">
                               

                                <div class="card card-custom gutter-b bg-white border-0">
                                    <div class="card-body">
                                        <div v-if="drivers.length">
                                            <h4>Selecciona un chofer para ver su ubicación</h4>
                                            <div class="form-group">
                                                <select v-model="selectedDriver" @change="getDriverRoute(selectedDriver)" class="form-control">
                                                <option disabled value="">Selecciona un chofer</option>
                                                <option v-for="driver in drivers" :key="driver.id" :value="driver.id">
                                                    {{ driver.nombre }} {{ driver.apellido }}
                                                </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div id="map" style="height: 400px; width: 100%;"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    
    </div>
    
    </template>
<script>
import ErrorHandling from "../../ErrorHandling";
import axios from 'axios';
import L from 'leaflet';
export default {
  
    data() {
        return {
           
            drivers: [],  
            selectedDriver: "",     
            map: null,        
          
            marker: null,
            errors: new ErrorHandling(),
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
            mapLoaded: false,
            updateInterval: null
        };
    },

    methods: {
        fetchaccounts(page_url) {
            this.$parent.loading = true;
            let vm = this;
            page_url = page_url || "/api/admin/drivers/list_driver";
            axios.get(page_url, this.token).then(res => {
                console.log(res.data.data);
                this.drivers = res.data.data;
         
            }).finally(() => (this.$parent.loading = false));
        },

      
        loadGoogleMaps() {
            if (typeof google === 'undefined' || typeof google.maps === 'undefined') {
                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDxLD_4B32kcYxZ4I1SYpkIPhX0oLlRbBg&libraries=places`;
                script.async = true;
                script.defer = true;
                script.onload = () => {
                    this.initMap(); // Cuando el script se cargue, inicializa el mapa
                };
                document.head.appendChild(script);
            }else if (!this.mapLoaded) {
                this.initMap(); // Inicializa el mapa si el script ya está cargado
            }

        },

        initMap() {
            const mapElement = document.getElementById('map');
            if (mapElement && !this.map) {  // Asegúrate de no volver a inicializar si el mapa ya existe
                this.map = new google.maps.Map(mapElement, {
                    center: { lat: -11.003920, lng: -66.057144 }, // Centro predeterminado
                    zoom: 13
                });
                this.mapLoaded = true; 
            }
        },

        showMap(latitude, longitude) {
            if (!this.mapLoaded) {
                console.log("Esperando a que el mapa se cargue...");
                this.loadGoogleMaps(); // Carga el mapa si no está cargado
                return; // Espera a que el mapa se cargue y se inicialice
            }
            const newLat = parseFloat(latitude);
            const newLng = parseFloat(longitude);
            const position = { lat: newLat, lng: newLng };
            console.log("uubicaciones", position);

           
            
            if (this.map) {
                this.map.setCenter(position);
              
                if (this.marker) {
                    console.log("Actualizando posición del marcador...");
                    if (position.lat && position.lng) {
                        this.marker.setPosition(position); // Actualiza la posición del marcador
                        console.log("Posición del marcador actualizada:", position);
                    } else {
                        console.error("Posición inválida:", position);
                    }
                } else {
                    // Crear un nuevo marcador si no existe
                    console.log("Creando nuevo marcador en la posición:", position);
                    this.marker = new google.maps.Marker({
                        position: position,
                        map: this.map,
                        title: 'Ubicación del chofer'
                    });
                    console.log("Marcador creado.");
                }
            }

        },
        getDriverRoute(driverId) {
        axios.get(`/api/getruta/${driverId}`, this.token)
            .then(response => {
                console.log("rutas", response.data.data);
            const location = response.data.data; 
            if (location.latitude && location.longitude) {
                this.showMap(parseFloat(location.latitude), parseFloat(location.longitude));
            } else {
                console.error("Datos de ubicación no válidos:", location);
            }
            })
            .catch(error => {
            this.errors.record(error.response.data.errors);
            });
        },
        startLocationUpdates(driverId) {
            this.getDriverRoute(driverId);
            this.updateInterval = setInterval(() => {
                this.getDriverRoute(driverId);
            }, 60000);
        },

        stopLocationUpdates() {
            if (this.updateInterval) {
                clearInterval(this.updateInterval);
                this.updateInterval = null;
            }
        }

    },
    watch: {
        selectedDriver(newDriverId) {
            if (newDriverId) {
                this.startLocationUpdates(newDriverId); 
            } else {
                this.stopLocationUpdates(); 
            }
        }
    },

    mounted() {
        
        var token = localStorage.getItem('token');
        this.token = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        this.fetchaccounts();
        this.initMap();
       
    },
    beforeDestroy() {
        this.stopLocationUpdates(); 
    }
};
</script>    