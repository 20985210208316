<style>
#map {
  height: 200px;
  width: 300px;
}

</style>
<template>

    <div>
        <div class="d-flex flex-column-fluid">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-12 col-xl-12">
                                <div class="card card-custom gutter-b bg-transparent shadow-none border-0">
                                    <div class="card-header align-items-center  border-bottom-dark px-0">
                                        <div class="card-title mb-0">
                                            <h3 class="card-label mb-0 font-weight-bold text-body">
                                                Agregar Conocimiento de Carga
                                            </h3>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-xl-12">
                                <div class="card card-custom gutter-b bg-white border-0">
                                    <div class="card-body">
                                        <form>

                                            <div class="form-group">

                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <div style="text-align: center;">
                                                            <span class="brand-text" style="text-align: center;">
                                                                <img
                                                                    style="height: 110px; align-content: center;"
                                                                    alt="Logo"
                                                                    src="/assets/images/misc/guia.png"
                                                                />
                                                            </span>
                                                        </div>
                                                                <p style="text-align: center;">
                                                                    SEGURIDAD - GARANTIA - RESPONSABILIDAD - RAPIDEZ
                                                                    <P style="text-align: center;"><small>Cel.: 74110503 - 76974927 Telf.Fax:4528984</small><br>
                                                                                                    <small>Cochabamba - Bolivia</small><br>
                                                                                                    <small>Jose Jhonny Peña Rojas</small><br>
                                                                                                    <small>NIT: 7907715015</small>
                                                                                                </P>
                                                                </p>

                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <p><small style="display: inline-block; margin-left: 50px;">SERVICIO DE PUERTA A PUERTA</small><br>
                                                                                           <small style="display: inline-block; margin-left: 50px;">COTIZACIONES A DOMICILIO SIN NINGUN COMPROMISO</small><br>
                                                                                           <small style="display: inline-block; margin-left: 50px;">SERVICIO DE TRANSPORTE</small><br>
                                                                                           <small style="display: inline-block; margin-left: 50px;">DE CARGA EN GENERAL</small><br>
                                                                                           <small style="display: inline-block; margin-left: 50px;">MUDANZAS, EMBALAJES</small><br>
                                                                                           <small style="display: inline-block; margin-left: 50px;">ALMACENAMIENTO Y LOCAL</small><br>
                                                                                           <small style="display: inline-block; margin-left: 50px;">LAS 24 HORAS</small></p>
                                                            <table class="table" style="float: left;">
                                                                <thead>
                                                                    <tr>

                                                                        <th width="5%">Fecha</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><input type="date" class="form-control" id="fecha" v-model="reconocimiento.fecha"></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                       </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <label class="text-body">Choferes</label>
                                                            <fieldset class="form-group mb-3 d-flex">
                                                                <select class="js-example-basic-single js-states form-control bg-transparent" v-model='reconocimiento.chofer_id' @change="appendChild($event,'select')">
                                                                    <option value="">Selecciona una opcion:</option>
                                                                    <option v-for='chofer in guia_dropdowns' :value='chofer.id'
                                                                        v-bind:selected="chofer.id"
                                                                        v-bind:key="chofer.id"
                                                                        v-if="chofer.status == 1"
                                                                        >{{ chofer.nombre }}
                                                                    </option>
                                                                </select>

                                                            </fieldset>
                                                            <small class="form-text text-danger" v-if="errors.has('chofer_id')" v-text="errors.get('chofer')"></small>
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">Destino: </label>
                                                            <input type="text" class="form-control" id="destino" v-model="reconocimiento.destino" placeholder="ingrese el Destino">
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">Dias Entrega: </label>
                                                            <input type="number" class="form-control" id="destino" v-model="reconocimiento.dias" placeholder="ingrese dias de entrega">
                                                        </div>
                                                        <div class="col-12 col-lg-12">
                                                            <div id="map" style="height: 400px; width: 100%;"></div>
                                                            <div class="form-group">
                                                            <label for="latitude">Latitud: </label>
                                                            <input type="text" class="form-control" v-model="reconocimiento.latitude" readonly>
                                                            </div>
                                                            <div class="form-group">
                                                            <label for="longitude">Longitud: </label>
                                                            <input type="text" class="form-control" v-model="reconocimiento.longitude" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <!-- start table  -->
                                            <div class="col-sm-12">
                                                <form @submit.prevent="agregarGuia">
                                                    <label class="text-body">Guias</label>
                                                            <fieldset class="form-group mb-3 d-flex">
                                                                <select class="js-example-basic-single js-states form-control bg-transparent" id="my-select" v-model='nguia' @change="appendChild($event,'select')">
                                                                    <option value="">Selecciona una opcion:</option>
                                                                    <option v-for='listguia in listadoguias' :value='listguia.id'
                                                                        v-bind:selected="listguia.id"
                                                                        v-bind:key="listguia.id"
                                                                        v-if="listguia.status == 1"
                                                                        >00{{ listguia.numero }}
                                                                    </option>
                                                                </select>

                                                            </fieldset>
                                                            <!-- <small class="form-text text-danger" v-if="errors.has('chofer_id')" v-text="errors.get('chofer')"></small> -->


                                                    <button type="submit"  class="btn btn-primary">Agregar producto</button>
                                                </form>
                                                <table class="table table-striped table-bordered table-hover" width="100%">
                                                    <thead>
                                                        <tr class="text-center table-primary1">
                                                            <th width="7%">Consignatario</th>
                                                            <th width="19%">Guia N°</th>
                                                            <th width="35%">N° bultos</th>
                                                            <th width="5%">Contenido</th>
                                                            <th width="3%">Importe</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(producto, index) in reconocimiento.productos" :key="index">
                                                            <td class="text-center">{{ producto.consignatario }}</td>
                                                            <td>{{ producto.guia }}</td>
                                                            <td>{{ producto.bultos }}</td>
                                                            <td class="text-center">{{ producto.contenido }}</td>
                                                            <td>{{ producto.importe }}</td>
                                                            <td>
                                                                <i class="nav-icon i-Close-Window font-weight-bold text-danger" style="cursor: pointer"

                                                                    >
                                                                </i>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                            <div class="col-sm-12">
                                                <div class="row">
                                                    <div class="col-sm-8">
                                                        <div class="form-group row">
                                                            <div class="col-sm-11"  style="border-right: 1px solid white;">
                                                            </div>
                                                            <div class="col-sm-1"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- en table  -->
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">Pago en Destino :</label>
                                                            <input type="text" class="form-control" id="pago_destino" v-model="reconocimiento.pago_destino" placeholder="Pago Destino">
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">Pago en Origen :</label>
                                                            <input type="text" class="form-control" id="pago_origen" v-model="reconocimiento.pago_origen" placeholder="Pago Origen">
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">Total a Flete:</label>
                                                            <input type="text" class="form-control" id="total" v-model="reconocimiento.total" placeholder="Total Flete">
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">A Cuenta:</label>
                                                            <input type="text" class="form-control" id="acuenta" v-model="reconocimiento.acuenta" placeholder="A Cuenta">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <button type="submit" class="btn btn-primary" @click.prevent="registrarReconocimiento()">Registrar</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>

   
  <script>
  import ErrorHandling from "./../../ErrorHandling";
  import axios from 'axios';
  import L from 'leaflet';

  export default {
    data() {
      return {
        activeItem: 'info-tab',
            reconocimiento: {
                fecha: null,
                destino: '',
                pago_origen: '',
                pago_destino: '',
                total: '',
                acuenta: '',
                chofer_id : '',
                dias : '',
                productos: [],
                latitude: null,
                longitude: null,

            },
            errors: {
                pago_origen: '',
                pago_destino: '',
                total: '',
                acuenta: ''
            },
        map: null,
        marker: null,    
        reconocimiento_id: '',
        ClipboardEvent: '',
        request_method : '',
        token: [],
        guia_dropdowns:[],
        listadoguias:[],
        consignatario: '',
        bultos : '',
        guia: '',
        contenido : '',
        guia_id: '',
        nguia: '',

        errors: new ErrorHandling(),
        csrf: document.querySelector('meta[name="csrf-token"]').getAttribute("content")
      }
    },
    mounted() {
        this.loadGoogleMaps();
        var token = localStorage.getItem('token');
        this.token = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
         this.guiadropdowns();

    },
    methods: {
        validarCI() {
            if (!this.reconocimiento.pago_origen || isNaN(this.reconocimiento.pago_origen)) {
                this.errors.pago_origen = 'El Pago origen debe ser un número';
            } else {
                this.errors.pago_origen = '';
            }
            },
        guiadropdowns(page_url) {
            this.$parent.loading = true;
            page_url = page_url || "/api/guias/create";
            axios.get(page_url, this.token).then(res => {
                this.guia_dropdowns = res.data.data;
                this.guia_id = res.data.numero.id + 1;
                this.listadoguias = res.data.guia;
                console.log(res.data.numero);
            }).finally(() => (this.$parent.loading = false));
        },
        findChild() {
      // implementación de la función findChild
        },
        appendChild(e,select){
            this.findChild(e.target.value,select);
        },
        registrarReconocimiento() {

        // Código para registrar el chofer en la base de datos
        var url = '/api/reconocimiento/add_reco';
        console.log(this.reconocimiento);
        this.request_method = 'post'
        axios.post(url, this.reconocimiento,this.token)
        .then(res => {
            if (res.data.status == true){
                this.$toaster.success(res.data.message);
                setTimeout(() => {
                    location.reload();
                 }, 1000);
            } else {
                        this.$toaster.error(res.data.message)
                    }
        })
        .catch(error => {
					this.error_message = '';
					this.errors = new ErrorHandling();
					if (error.response.status == 422) {
						if(error.response.data.status == 'Error'){
                        this.$toaster.error(error.response.data.message)
						}
						else{
							this.errors.record(error.response.data.errors);
						}
					}
				}).finally(() => (this.$parent.loading = false));
        console.log('Reconocimiento de carga registrada');
      },
       agregarGuia() {
        const id = this.nguia;
        this.$parent.loading = true;

            axios.get(`/api/admin/reconocimiento/${id}`, this.token).then(res => {
                const nuevosProductos = res.data.data;
                nuevosProductos.forEach(producto => {
                    producto.reconocimiento_id = res.data.numero.id + 1;
                });
                this.reconocimiento.productos = this.reconocimiento.productos.concat(nuevosProductos);

                console.log(res.data);
            }).finally(() => (this.$parent.loading = false));

        // this.reconocimiento.productos.push();


      },
      loadGoogleMaps() {
      if (typeof google === 'undefined') {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDxLD_4B32kcYxZ4I1SYpkIPhX0oLlRbBg&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = this.initMap;  // Inicializar el mapa después de que se cargue el script
        document.head.appendChild(script);
      } else {
        this.initMap();
      }
    },
    initMap() {
      const mapElement = document.getElementById('map');
      if (mapElement) {
        this.map = new google.maps.Map(mapElement, {
          center: { lat: -11.003920, lng: -66.057144 }, 
          zoom: 13
        });

        this.map.addListener('click', (e) => {
          this.setMarker(e.latLng);
        });
      }
    },
    setMarker(location) {
      if (this.marker) {
        this.marker.setPosition(location);
      } else {
        this.marker = new google.maps.Marker({
          position: location,
          map: this.map,
        });
      }
      this.reconocimiento.latitude = location.lat();
      this.reconocimiento.longitude = location.lng();
    },


    },
    computed: {},
    components: {
    },
 
  };
  </script>
  