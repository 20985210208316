var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0"
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("form", [
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "col-12 col-sm-12" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-6 col-lg-6" }, [
                                _c(
                                  "table",
                                  {
                                    staticClass: "table",
                                    staticStyle: { float: "left" }
                                  },
                                  [
                                    _vm._m(1),
                                    _vm._v(" "),
                                    _c("tbody", [
                                      _c("tr", [
                                        _c("td", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.fecha,
                                                expression: "fecha"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "date",
                                              id: "fecha"
                                            },
                                            domProps: { value: _vm.fecha },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.fecha = $event.target.value
                                              }
                                            }
                                          })
                                        ])
                                      ])
                                    ])
                                  ]
                                )
                              ])
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "col-12 col-sm-12" }, [
                            _c("div", { staticClass: "col-sm-12" }, [
                              _c(
                                "form",
                                {
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return _vm.agregarGuia.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("label", { staticClass: "text-body" }, [
                                    _vm._v("Guias")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "fieldset",
                                    { staticClass: "form-group mb-3 d-flex" },
                                    [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.nguia,
                                              expression: "nguia"
                                            }
                                          ],
                                          staticClass:
                                            "js-example-basic-single js-states form-control bg-transparent",
                                          attrs: { id: "my-select" },
                                          on: {
                                            change: [
                                              function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.nguia = $event.target
                                                  .multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              },
                                              function($event) {
                                                return _vm.appendChild(
                                                  $event,
                                                  "select"
                                                )
                                              }
                                            ]
                                          }
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "" } },
                                            [_vm._v("Selecciona una opcion:")]
                                          ),
                                          _vm._v(" "),
                                          _vm._l(_vm.listadoguias, function(
                                            listguia
                                          ) {
                                            return listguia.status == 1
                                              ? _c(
                                                  "option",
                                                  {
                                                    key: listguia.id,
                                                    domProps: {
                                                      value: listguia.id,
                                                      selected: listguia.id
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "00" +
                                                        _vm._s(
                                                          listguia.numero
                                                        ) +
                                                        "\n                                                                "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          })
                                        ],
                                        2
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      attrs: { type: "submit" }
                                    },
                                    [_vm._v("Agregar producto")]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "table",
                                {
                                  staticClass:
                                    "table table-striped table-bordered table-hover",
                                  attrs: { width: "100%" }
                                },
                                [
                                  _vm._m(2),
                                  _vm._v(" "),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.guias, function(guia, index) {
                                      return _c("tr", { key: index }, [
                                        _c("td", [
                                          _vm._v("00" + _vm._s(guia.numero))
                                        ]),
                                        _vm._v(" "),
                                        _vm._m(3, true)
                                      ])
                                    }),
                                    0
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-6 col-lg-6" }, [
                                _c("label", { attrs: { for: "destino" } }, [
                                  _vm._v("Total:")
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.monto,
                                      expression: "monto"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "correo",
                                    placeholder: "Ingrese el total"
                                  },
                                  domProps: { value: _vm.monto },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.monto = $event.target.value
                                    }
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-6 col-lg-6" }, [
                                _c("label", { attrs: { for: "destino" } }, [
                                  _vm._v("Despachante:")
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.despachante,
                                      expression: "despachante"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "correo",
                                    placeholder: "Ingrese Despachante"
                                  },
                                  domProps: { value: _vm.despachante },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.despachante = $event.target.value
                                    }
                                  }
                                })
                              ])
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "col-12 col-sm-12" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-6 col-lg-6" }, [
                                _c("label", { attrs: { for: "origen" } }, [
                                  _vm._v("N° Factura:")
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.numero_factura,
                                      expression: "numero_factura"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "direccion",
                                    placeholder: "Ingrese su numero de factura"
                                  },
                                  domProps: { value: _vm.numero_factura },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.numero_factura = $event.target.value
                                    }
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-6 col-lg-6" }, [
                                _c("label", { attrs: { for: "origen" } }, [
                                  _vm._v("Tipo Carga:")
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.tipo_carga,
                                      expression: "tipo_carga"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "direccion",
                                    placeholder: "Ingrese el tipo de carga"
                                  },
                                  domProps: { value: _vm.tipo_carga },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.tipo_carga = $event.target.value
                                    }
                                  }
                                })
                              ])
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "col-12 col-sm-12" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-6 col-lg-6" }, [
                                _c("label", { attrs: { for: "origen" } }, [
                                  _vm._v("Estado:")
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.estado,
                                      expression: "estado"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "checkbox",
                                    id: "estado",
                                    placeholder: "Ingrese un monto"
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.estado)
                                      ? _vm._i(_vm.estado, null) > -1
                                      : _vm.estado
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.estado,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.estado = $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.estado = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.estado = $$c
                                      }
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _vm.estado
                                  ? _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("estadoTexto")(_vm.estado)
                                        )
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { type: "submit" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.registrarFactura()
                                }
                              }
                            },
                            [_vm._v("Registrar Factura")]
                          )
                        ])
                      ])
                    ])
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
        _c(
          "div",
          {
            staticClass:
              "card card-custom gutter-b bg-transparent shadow-none border-0"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "card-header align-items-center  border-bottom-dark px-0"
              },
              [
                _c("div", { staticClass: "card-title mb-0" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "card-label mb-0 font-weight-bold text-body"
                    },
                    [
                      _vm._v(
                        "\n                                            Agregar Nueva Factura\n                                        "
                      )
                    ]
                  )
                ])
              ]
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [_c("th", { attrs: { width: "5%" } }, [_vm._v("Fecha")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "text-center table-primary1" }, [
        _c("th", { attrs: { width: "19%" } }, [_vm._v("Guia N°")]),
        _vm._v(" "),
        _c("th")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("i", {
        staticClass: "nav-icon i-Close-Window font-weight-bold text-danger",
        staticStyle: { cursor: "pointer" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }