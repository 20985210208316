<template>
    <div>
        <div class="d-flex flex-column-fluid">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-12 col-xl-12">
                                <div class="card card-custom gutter-b bg-transparent shadow-none border-0">
                                    <div class="card-header align-items-center  border-bottom-dark px-0">
                                        <div class="card-title mb-0">
                                            <h3 class="card-label mb-0 font-weight-bold text-body">
                                                Agregar Nueva Factura
                                            </h3>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-xl-12">
                                <div class="card card-custom gutter-b bg-white border-0">
                                    <div class="card-body">
                                        <form>
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                             <table class="table" style="float: left;">
                                                                <thead>
                                                                    <tr>

                                                                        <th width="5%">Fecha</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><input type="date" class="form-control" id="fecha" v-model="fecha"></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                       </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="col-sm-12">
                                                <form @submit.prevent="agregarGuia">
                                                    <label class="text-body">Guias</label>
                                                            <fieldset class="form-group mb-3 d-flex">
                                                                <select class="js-example-basic-single js-states form-control bg-transparent" id="my-select" v-model='nguia' @change="appendChild($event,'select')">
                                                                    <option value="">Selecciona una opcion:</option>
                                                                    <option v-for='listguia in listadoguias' :value='listguia.id'
                                                                        v-bind:selected="listguia.id"
                                                                        v-bind:key="listguia.id"
                                                                        v-if="listguia.status == 1"
                                                                        >00{{ listguia.numero }}
                                                                    </option>
                                                                </select>

                                                            </fieldset>
                                                            <!-- <small class="form-text text-danger" v-if="errors.has('chofer_id')" v-text="errors.get('chofer')"></small> -->


                                                    <button type="submit"  class="btn btn-primary">Agregar producto</button>
                                                </form>
                                                <table class="table table-striped table-bordered table-hover" width="100%">
                                                    <thead>
                                                        <tr class="text-center table-primary1">
                                                            <th width="19%">Guia N°</th>
                                                            <!-- <th width="19%">Empresa</th>
                                                            <th width="19%">Destino</th> -->
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(guia, index) in guias" :key="index">

                                                            <td>00{{ guia.numero }}</td>
                                                            <!-- <td>{{ guia.remitente }}</td>
                                                            <td>{{ guia.destino }}</td> -->
                                                            <td>
                                                                <i class="nav-icon i-Close-Window font-weight-bold text-danger" style="cursor: pointer"
                                                                    >
                                                                </i>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">Total:</label>
                                                            <input type="text" class="form-control" id="correo" v-model="monto" placeholder="Ingrese el total">
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">Despachante:</label>
                                                            <input type="text" class="form-control" id="correo" v-model="despachante" placeholder="Ingrese Despachante">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">N° Factura:</label>
                                                            <input type="text" class="form-control" id="direccion" v-model="numero_factura" placeholder="Ingrese su numero de factura">
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">Tipo Carga:</label>
                                                            <input type="text" class="form-control" id="direccion" v-model="tipo_carga" placeholder="Ingrese el tipo de carga">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">Estado:</label>
                                                            <input type="checkbox" class="form-control" id="estado" v-model="estado" placeholder="Ingrese un monto">
                                                            <p v-if="estado">{{ estado | estadoTexto }}</p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <button type="submit" class="btn btn-primary" @click.prevent="registrarFactura()">Registrar Factura</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>

  <script>
  import ErrorHandling from "./../../ErrorHandling";
  export default {
    data() {
      return {
        activeItem: 'info-tab',

        monto: '',
        factura: '',
        tipo_carga: '',
        empresa: '',
        despachante: '',
        estado: false,
        numero_factura : '',
        fecha: '',
        destino : '',
        chofer_id : '',
        guias: [],

        ClipboardEvent: '',
        request_method : '',
        listadoguias : [],
        nguia: '',
        token: [],
        errors: new ErrorHandling(),
        csrf: document.querySelector('meta[name="csrf-token"]').getAttribute("content")
      }
    },
    methods: {
        guiadropdowns(page_url) {
            this.$parent.loading = true;
            page_url = page_url || "/api/guias/create";
            axios.get(page_url, this.token).then(res => {
                this.listadoguias = res.data.guia;

            }).finally(() => (this.$parent.loading = false));
        },
        agregarGuia() {
            const id = this.nguia;
            this.$parent.loading = true;

                axios.get(`/api/admin/lisguias/${id}`, this.token).then(res => {

                    const nuevosProductos = res.data.data;
                    this.guias = this.guias.concat(nuevosProductos);
                    for (let propiedad in nuevosProductos) {
                        this.empresa = nuevosProductos[propiedad].empresa;
                        this.destino = nuevosProductos[propiedad].destino;
                        this.chofer_id = nuevosProductos[propiedad].chofer_id;
                        console.log(nuevosProductos[propiedad].empresa);
                        console.log(this.destino);
                        console.log(nuevosProductos[propiedad].chofer_id);
                        }

                }).finally(() => (this.$parent.loading = false));
        },
        findChild() {
      // implementación de la función findChild
        },
        appendChild(e,select){
            this.findChild(e.target.value,select);
        },

      registrarFactura() {
        // Código para registrar el chofer en la base de datos
        var url = '/api/factura';
        this.request_method = 'post'
        axios[this.request_method](url, {
            // data a guardar
            monto: this.monto,
            factura: this.factura,
            tipo_carga: this.tipo_carga,
            empresa: this.empresa,
            despachante: this.despachante,
            destino : this.destino,
            estado: this.$options.filters.estadoTexto(this.estado),
            numero_factura : this.numero_factura,
            fecha: this.fecha,
            chofer_id : this.chofer_id,
            guias: this.guias,
        }, this.token)
        .then(res => {
            if (res.data.status == true){
                this.$toaster.success(res.data.message);
                setTimeout(() => {
                    location.reload();
                 }, 1000);
            } else {
                        this.$toaster.error(res.data.message)
                    }
        })
        .catch(error => {
					this.error_message = '';
					this.errors = new ErrorHandling();
					if (error.response.status == 422) {
						if(error.response.data.status == 'Error'){
                        this.$toaster.error(error.response.data.message)
						}
						else{
							this.errors.record(error.response.data.errors);
						}
					}
				}).finally(() => (this.$parent.loading = false));
        console.log('FACTURA REGISTRADA');

      }
    },
    mounted() {
        var token = localStorage.getItem('token');
        this.token = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
         this.guiadropdowns();

    },
    filters: {
        estadoTexto(value) {
            return value ? 'Pagado' : 'Pendiente'
        }
    }
  }
  </script>
