var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0"
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("form", [
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "col-12 col-sm-12" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-6 col-lg-6" }, [
                                _vm._m(1),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  { staticStyle: { "text-align": "center" } },
                                  [
                                    _vm._v(
                                      "\n                                                                SEGURIDAD - GARANTIA - RESPONSABILIDAD - RAPIDEZ\n                                                                "
                                    ),
                                    _c(
                                      "P",
                                      {
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _c("small", [
                                          _vm._v(
                                            "Cel.: 74110503 - 76974927 Telf.Fax:4528984"
                                          )
                                        ]),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("small", [
                                          _vm._v("Cochabamba - Bolivia")
                                        ]),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("small", [
                                          _vm._v("Jose Jhonny Peña Rojas")
                                        ]),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("small", [_vm._v("NIT: 7907715015")])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-6 col-lg-6" }, [
                                _vm._m(2),
                                _vm._v(" "),
                                _c(
                                  "table",
                                  {
                                    staticClass: "table",
                                    staticStyle: { float: "left" }
                                  },
                                  [
                                    _vm._m(3),
                                    _vm._v(" "),
                                    _c("tbody", [
                                      _c("tr", [
                                        _c("td", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.reconocimiento.fecha,
                                                expression:
                                                  "reconocimiento.fecha"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "date",
                                              id: "fecha"
                                            },
                                            domProps: {
                                              value: _vm.reconocimiento.fecha
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.reconocimiento,
                                                  "fecha",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ])
                                      ])
                                    ])
                                  ]
                                )
                              ])
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "col-12 col-sm-12" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-12" }, [
                                _c("label", { staticClass: "text-body" }, [
                                  _vm._v("Choferes")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "fieldset",
                                  { staticClass: "form-group mb-3 d-flex" },
                                  [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.reconocimiento.chofer_id,
                                            expression:
                                              "reconocimiento.chofer_id"
                                          }
                                        ],
                                        staticClass:
                                          "js-example-basic-single js-states form-control bg-transparent",
                                        on: {
                                          change: [
                                            function($event) {
                                              var $$selectedVal = Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function(o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function(o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                              _vm.$set(
                                                _vm.reconocimiento,
                                                "chofer_id",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                            function($event) {
                                              return _vm.appendChild(
                                                $event,
                                                "select"
                                              )
                                            }
                                          ]
                                        }
                                      },
                                      [
                                        _c("option", { attrs: { value: "" } }, [
                                          _vm._v("Selecciona una opcion:")
                                        ]),
                                        _vm._v(" "),
                                        _vm._l(_vm.guia_dropdowns, function(
                                          chofer
                                        ) {
                                          return chofer.status == 1
                                            ? _c(
                                                "option",
                                                {
                                                  key: chofer.id,
                                                  domProps: {
                                                    value: chofer.id,
                                                    selected: chofer.id
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(chofer.nombre) +
                                                      "\n                                                                "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        })
                                      ],
                                      2
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.errors.has("chofer_id")
                                  ? _c("small", {
                                      staticClass: "form-text text-danger",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.errors.get("chofer")
                                        )
                                      }
                                    })
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-6 col-lg-6" }, [
                                _c("label", { attrs: { for: "destino" } }, [
                                  _vm._v("Destino: ")
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reconocimiento.destino,
                                      expression: "reconocimiento.destino"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "destino",
                                    placeholder: "ingrese el Destino"
                                  },
                                  domProps: {
                                    value: _vm.reconocimiento.destino
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.reconocimiento,
                                        "destino",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-6 col-lg-6" }, [
                                _c("label", { attrs: { for: "destino" } }, [
                                  _vm._v("Dias Entrega: ")
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reconocimiento.dias,
                                      expression: "reconocimiento.dias"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "number",
                                    id: "destino",
                                    placeholder: "ingrese dias de entrega"
                                  },
                                  domProps: { value: _vm.reconocimiento.dias },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.reconocimiento,
                                        "dias",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-12 col-lg-12" }, [
                                _c("div", {
                                  staticStyle: {
                                    height: "400px",
                                    width: "100%"
                                  },
                                  attrs: { id: "map" }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-group" }, [
                                  _c("label", { attrs: { for: "latitude" } }, [
                                    _vm._v("Latitud: ")
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.reconocimiento.latitude,
                                        expression: "reconocimiento.latitude"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text", readonly: "" },
                                    domProps: {
                                      value: _vm.reconocimiento.latitude
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.reconocimiento,
                                          "latitude",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-group" }, [
                                  _c("label", { attrs: { for: "longitude" } }, [
                                    _vm._v("Longitud: ")
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.reconocimiento.longitude,
                                        expression: "reconocimiento.longitude"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text", readonly: "" },
                                    domProps: {
                                      value: _vm.reconocimiento.longitude
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.reconocimiento,
                                          "longitude",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ])
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c(
                            "form",
                            {
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.agregarGuia.apply(null, arguments)
                                }
                              }
                            },
                            [
                              _c("label", { staticClass: "text-body" }, [
                                _vm._v("Guias")
                              ]),
                              _vm._v(" "),
                              _c(
                                "fieldset",
                                { staticClass: "form-group mb-3 d-flex" },
                                [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.nguia,
                                          expression: "nguia"
                                        }
                                      ],
                                      staticClass:
                                        "js-example-basic-single js-states form-control bg-transparent",
                                      attrs: { id: "my-select" },
                                      on: {
                                        change: [
                                          function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.nguia = $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          },
                                          function($event) {
                                            return _vm.appendChild(
                                              $event,
                                              "select"
                                            )
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v("Selecciona una opcion:")
                                      ]),
                                      _vm._v(" "),
                                      _vm._l(_vm.listadoguias, function(
                                        listguia
                                      ) {
                                        return listguia.status == 1
                                          ? _c(
                                              "option",
                                              {
                                                key: listguia.id,
                                                domProps: {
                                                  value: listguia.id,
                                                  selected: listguia.id
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "00" +
                                                    _vm._s(listguia.numero) +
                                                    "\n                                                                "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      })
                                    ],
                                    2
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: { type: "submit" }
                                },
                                [_vm._v("Agregar producto")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "table",
                            {
                              staticClass:
                                "table table-striped table-bordered table-hover",
                              attrs: { width: "100%" }
                            },
                            [
                              _vm._m(4),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(_vm.reconocimiento.productos, function(
                                  producto,
                                  index
                                ) {
                                  return _c("tr", { key: index }, [
                                    _c("td", { staticClass: "text-center" }, [
                                      _vm._v(_vm._s(producto.consignatario))
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(_vm._s(producto.guia))]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v(_vm._s(producto.bultos))]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "text-center" }, [
                                      _vm._v(_vm._s(producto.contenido))
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(_vm._s(producto.importe))
                                    ]),
                                    _vm._v(" "),
                                    _vm._m(5, true)
                                  ])
                                }),
                                0
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _vm._m(6),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "col-12 col-sm-12" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-6 col-lg-6" }, [
                                _c("label", { attrs: { for: "origen" } }, [
                                  _vm._v("Pago en Destino :")
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reconocimiento.pago_destino,
                                      expression: "reconocimiento.pago_destino"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "pago_destino",
                                    placeholder: "Pago Destino"
                                  },
                                  domProps: {
                                    value: _vm.reconocimiento.pago_destino
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.reconocimiento,
                                        "pago_destino",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-6 col-lg-6" }, [
                                _c("label", { attrs: { for: "destino" } }, [
                                  _vm._v("Pago en Origen :")
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reconocimiento.pago_origen,
                                      expression: "reconocimiento.pago_origen"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "pago_origen",
                                    placeholder: "Pago Origen"
                                  },
                                  domProps: {
                                    value: _vm.reconocimiento.pago_origen
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.reconocimiento,
                                        "pago_origen",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-6 col-lg-6" }, [
                                _c("label", { attrs: { for: "origen" } }, [
                                  _vm._v("Total a Flete:")
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reconocimiento.total,
                                      expression: "reconocimiento.total"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "total",
                                    placeholder: "Total Flete"
                                  },
                                  domProps: { value: _vm.reconocimiento.total },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.reconocimiento,
                                        "total",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-6 col-lg-6" }, [
                                _c("label", { attrs: { for: "destino" } }, [
                                  _vm._v("A Cuenta:")
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reconocimiento.acuenta,
                                      expression: "reconocimiento.acuenta"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    id: "acuenta",
                                    placeholder: "A Cuenta"
                                  },
                                  domProps: {
                                    value: _vm.reconocimiento.acuenta
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.reconocimiento,
                                        "acuenta",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { type: "submit" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.registrarReconocimiento()
                                }
                              }
                            },
                            [_vm._v("Registrar")]
                          )
                        ])
                      ])
                    ])
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
        _c(
          "div",
          {
            staticClass:
              "card card-custom gutter-b bg-transparent shadow-none border-0"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "card-header align-items-center  border-bottom-dark px-0"
              },
              [
                _c("div", { staticClass: "card-title mb-0" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "card-label mb-0 font-weight-bold text-body"
                    },
                    [
                      _vm._v(
                        "\n                                            Agregar Conocimiento de Carga\n                                        "
                      )
                    ]
                  )
                ])
              ]
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "text-align": "center" } }, [
      _c(
        "span",
        { staticClass: "brand-text", staticStyle: { "text-align": "center" } },
        [
          _c("img", {
            staticStyle: { height: "110px", "align-content": "center" },
            attrs: { alt: "Logo", src: "/assets/images/misc/guia.png" }
          })
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c(
        "small",
        { staticStyle: { display: "inline-block", "margin-left": "50px" } },
        [_vm._v("SERVICIO DE PUERTA A PUERTA")]
      ),
      _c("br"),
      _vm._v(" "),
      _c(
        "small",
        { staticStyle: { display: "inline-block", "margin-left": "50px" } },
        [_vm._v("COTIZACIONES A DOMICILIO SIN NINGUN COMPROMISO")]
      ),
      _c("br"),
      _vm._v(" "),
      _c(
        "small",
        { staticStyle: { display: "inline-block", "margin-left": "50px" } },
        [_vm._v("SERVICIO DE TRANSPORTE")]
      ),
      _c("br"),
      _vm._v(" "),
      _c(
        "small",
        { staticStyle: { display: "inline-block", "margin-left": "50px" } },
        [_vm._v("DE CARGA EN GENERAL")]
      ),
      _c("br"),
      _vm._v(" "),
      _c(
        "small",
        { staticStyle: { display: "inline-block", "margin-left": "50px" } },
        [_vm._v("MUDANZAS, EMBALAJES")]
      ),
      _c("br"),
      _vm._v(" "),
      _c(
        "small",
        { staticStyle: { display: "inline-block", "margin-left": "50px" } },
        [_vm._v("ALMACENAMIENTO Y LOCAL")]
      ),
      _c("br"),
      _vm._v(" "),
      _c(
        "small",
        { staticStyle: { display: "inline-block", "margin-left": "50px" } },
        [_vm._v("LAS 24 HORAS")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [_c("th", { attrs: { width: "5%" } }, [_vm._v("Fecha")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "text-center table-primary1" }, [
        _c("th", { attrs: { width: "7%" } }, [_vm._v("Consignatario")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "19%" } }, [_vm._v("Guia N°")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "35%" } }, [_vm._v("N° bultos")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "5%" } }, [_vm._v("Contenido")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "3%" } }, [_vm._v("Importe")]),
        _vm._v(" "),
        _c("th")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("i", {
        staticClass: "nav-icon i-Close-Window font-weight-bold text-danger",
        staticStyle: { cursor: "pointer" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-8" }, [
          _c("div", { staticClass: "form-group row" }, [
            _c("div", {
              staticClass: "col-sm-11",
              staticStyle: { "border-right": "1px solid white" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-1" })
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }