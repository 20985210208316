var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "card card-custom gutter-b bg-transparent shadow-none border-0"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-header align-items-center  border-bottom-dark px-0"
                      },
                      [
                        _c("div", { staticClass: "card-title mb-0" }, [
                          _c(
                            "h3",
                            {
                              staticClass:
                                "card-label mb-0 font-weight-bold text-body"
                            },
                            [
                              _vm._v(
                                "\n                                 " +
                                  _vm._s(
                                    this.$route.params.id ? "Edit" : "Add"
                                  ) +
                                  " Product\n                              "
                              )
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-body pb-0 pt-4 " }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-center" },
                        [
                          _c(
                            "ul",
                            {
                              staticClass: "nav nav-pills mb-0",
                              attrs: { id: "pills-tabmain", role: "tablist" }
                            },
                            [
                              _c("li", { staticClass: "nav-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link",
                                    class: { active: _vm.isActive("info-tab") },
                                    attrs: { href: "#info-tab" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.setActive("info-tab")
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "bi bi-file-earmark-text",
                                        attrs: {
                                          width: "20px",
                                          height: "20px",
                                          viewBox: "0 0 16 16",
                                          fill: "currentColor",
                                          xmlns: "http://www.w3.org/2000/svg"
                                        }
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d:
                                              "M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("path", {
                                          attrs: {
                                            d:
                                              "M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("path", {
                                          attrs: {
                                            "fill-rule": "evenodd",
                                            d:
                                              "M5 11.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                                       Basic Info\n                                    "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "nav-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link",
                                    class: {
                                      active: _vm.isActive("ad-info-tab")
                                    },
                                    attrs: { href: "#ad-info-tab" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.setActive("ad-info-tab")
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "bi bi-receipt",
                                        attrs: {
                                          width: "20px",
                                          height: "20px",
                                          viewBox: "0 0 16 16",
                                          fill: "currentColor",
                                          xmlns: "http://www.w3.org/2000/svg"
                                        }
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            "fill-rule": "evenodd",
                                            d:
                                              "M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("path", {
                                          attrs: {
                                            "fill-rule": "evenodd",
                                            d:
                                              "M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z"
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                                       Advance Info\n                                    "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "nav-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link",
                                    class: { active: _vm.isActive("seo-tab") },
                                    attrs: { href: "#seo-tab" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.setActive("seo-tab")
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "bi bi-receipt",
                                        attrs: {
                                          width: "20px",
                                          height: "20px",
                                          viewBox: "0 0 16 16",
                                          fill: "currentColor",
                                          xmlns: "http://www.w3.org/2000/svg"
                                        }
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            "fill-rule": "evenodd",
                                            d:
                                              "M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("path", {
                                          attrs: {
                                            "fill-rule": "evenodd",
                                            d:
                                              "M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z"
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                                       SEO\n                                    "
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        ]
                      )
                    ])
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "tab-content",
                    attrs: { id: "pills-tabContent" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "tab-pane fade",
                        class: { "active show": _vm.isActive("info-tab") }
                      },
                      [
                        _c("BasicInfo", {
                          attrs: {
                            errors: _vm.errors,
                            product: _vm.product,
                            edit: _vm.edit
                          },
                          on: {
                            setCategoryInChild: _vm.setCategory,
                            setTitleInChild: _vm.setTitle,
                            setDescInChild: _vm.setDesc,
                            setGallaryIdInChild: _vm.setGallaryId,
                            setActiveInChild: _vm.setActiveFromChild,
                            isActiveInChild: _vm.isActiveFromChild,
                            setVideoUrlInChild: _vm.setVideoUrl
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "tab-pane fade",
                        class: { "active show": _vm.isActive("ad-info-tab") }
                      },
                      [
                        _c("AdvanceInfo", {
                          attrs: {
                            errors: _vm.errors,
                            product: _vm.product,
                            edit: _vm.edit
                          },
                          on: {
                            setUnitInChild: _vm.setUnit,
                            setProductWeightInChild: _vm.setProductWeight,
                            setBrandInChild: _vm.setBrand,
                            setProductMaxOrderInChild: _vm.setProductMaxOrder,
                            setProductMinOrderInChild: _vm.setProductMinOrder,
                            setPriceInChild: _vm.setPrice,
                            setDiscountPriceInChild: _vm.setDiscountPrice,
                            setProductTypeInChild: _vm.setProductType,
                            setProductStatusInChild: _vm.setProductStatus,
                            setIsFeaturedInChild: _vm.setIsFeatured,
                            setIsPointsInChild: _vm.setIsPoints,
                            addProductInChild: _vm.addProduct,
                            setActiveInChild: _vm.setActiveFromChild,
                            isActiveInChild: _vm.isActiveFromChild,
                            setAttributesInChild: _vm.setAttributes,
                            setVariationsInChild: _vm.setVariations,
                            setCombinationPriceInChild: _vm.setCombinationPrice,
                            setCombinationGalleryInChild:
                              _vm.setCombinationGallery,
                            setCombinationSkuInChild: _vm.setCombinationSku,
                            setProductskuInChild: _vm.setSku
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "tab-pane fade",
                        class: { "active show": _vm.isActive("seo-tab") }
                      },
                      [
                        _c("Seo", {
                          attrs: {
                            product: _vm.product,
                            edit: _vm.edit,
                            errors: _vm.errors
                          },
                          on: {
                            setSeoMetaTagInChild: _vm.setSeoMetaTag,
                            setSeoDescInChild: _vm.setSeoDesc,
                            setActiveInChild: _vm.setActiveFromChild,
                            isActiveInChild: _vm.isActiveFromChild,
                            addProductInChild: _vm.addProduct
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }