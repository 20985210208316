<template>
    <div>
        <div class="d-flex flex-column-fluid">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-12 col-xl-12">
                                <div class="card card-custom gutter-b bg-transparent shadow-none border-0">
                                    <div class="card-header align-items-center  border-bottom-dark px-0">
                                        <div class="card-title mb-0">
                                            <h3 class="card-label mb-0 font-weight-bold text-body">
                                                Agregar Nueva Guia
                                            </h3>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-xl-12">
                                <div class="card card-custom gutter-b bg-white border-0">
                                    <div class="card-body">
                                        <form>
                                          
                                            <div class="form-group">
                                                
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <div style="text-align: center;">
                                                            <span class="brand-text" style="text-align: center;">
                                                                <img
                                                                    style="height: 110px; align-content: center;"
                                                                    alt="Logo"
                                                                    src="/assets/images/misc/LOGO.jpeg"
                                                                />
                                                            </span>
                                                        </div>
                                                                <p style="text-align: center;">
                                                                    SEGURIDAD - GARANTIA - RESPONSABILIDAD - RAPIDEZ
                                                                    <P style="text-align: center;"><small>Cel.: 74110503 - 76974927 Telf.Fax:4528984</small><br>
                                                                                                    <small>Cochabamba - Bolivia</small><br>
                                                                                                    <small>Jose Jhonny Peña Rojas</small><br>
                                                                                                    <small>NIT: 7907715015</small>
                                                                                                </P>
                                                                </p>
                                                           
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <p><small style="display: inline-block; margin-left: 50px;">SERVICIO DE PUERTA A PUERTA</small><br>
                                                                                           <small style="display: inline-block; margin-left: 50px;">COTIZACIONES A DOMICILIO SIN NINGUN COMPROMISO</small><br>
                                                                                           <small style="display: inline-block; margin-left: 50px;">SERVICIO DE TRANSPORTE</small><br>
                                                                                           <small style="display: inline-block; margin-left: 50px;">DE CARGA EN GENERAL</small><br>
                                                                                           <small style="display: inline-block; margin-left: 50px;">MUDANZAS, EMBALAJES</small><br>
                                                                                           <small style="display: inline-block; margin-left: 50px;">ALMACENAMIENTO Y LOCAL</small><br>
                                                                                           <small style="display: inline-block; margin-left: 50px;">LAS 24 HORAS</small></p>
                                                            <table class="table" style="float: left;">
                                                                <thead>
                                                                    <tr>
                                                                        <th width="5%">Lugar</th>
                                                                        <th width="5%">Fecha</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><input type="text" class="form-control" id="lugar" v-model="guia.lugar" placeholder="Ingrese un lugar "></td>
                                                                        <td><input type="date" class="form-control" id="fecha" v-model="guia.fecha"></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                       </div>
                                                    </div>
                                                </div>	
                                            </div>
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">Remitente:</label>
                                                            <input type="text" class="form-control" id="remitente" v-model="guia.remitente" placeholder="Ingrese nombre remitente">
                                                        
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">Destino: </label>
                                                            <input type="text" class="form-control" id="destino" v-model="guia.destino" placeholder="ingrese el Destino">
                                                        </div>
                                                    </div>
                                                </div>	
                                            </div>
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">Consignatario:</label>
                                                            <input type="text" class="form-control" id="consignatario" v-model="guia.consignatario" placeholder="Ingrese el consignatario">
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">Telefono:</label>
                                                            <input type="text" class="form-control" id="telefono" v-model="guia.telefono" placeholder="ingrese su telefono">
                                                        </div>
                                                    </div>
                                                </div>	
                                            </div>
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">Direccion:</label>
                                                            <input type="text" class="form-control" id="direccion" v-model="guia.direccion" placeholder="Ingrese su direccion">
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">Telf:</label>
                                                            <input type="text" class="form-control" id="telefono" v-model="guia.telefono2" placeholder="ingrese su telefono">
                                                        </div>
                                                    </div>
                                                </div>	
                                            </div>
                                            <!-- start table  -->
                                            <div class="col-sm-12">
                                                <form @submit.prevent="agregarProducto">
                                                    <label>
                                                    N°Bultos:
                                                    <input type="text" class="form-control" v-model="nbultos">
                                                    </label>
                                                    <label>
                                                    Descripcion:
                                                    <input type="text" class="form-control" v-model="descripcion">
                                                    </label>
                                                    <label>
                                                    Kilos:
                                                    <input type="text" class="form-control" v-model="kilos">
                                                    </label>
                                                    <label>
                                                    Total:
                                                    <input type="text" class="form-control" v-model="total">
                                                    <input type="hidden" class="form-control" v-model="guia_id">
                                                    </label>
                                                    <button type="submit"  class="btn btn-primary">Agregar producto</button>
                                                </form>
                                                <table class="table table-striped table-bordered table-hover" width="100%">
                                                    <thead>
                                                        <tr class="text-center table-primary1">
                                                            <th width="7%">N° BULTOS</th>
                                                            <th width="19%">DESCRICION</th>
                                                            <th width="35%">KILOS</th>
                                                            <th width="5%">TOTAL</th>
                                                            <th width="3%"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(producto, index) in guia.productos" :key="index">
                                                            <td class="text-center">{{ producto.nbultos }}</td>
                                                            <td>{{ producto.descripcion }}</td>
                                                            <td>{{ producto.kilos }}</td>
                                                            <td class="text-center">{{ producto.total }}</td>
                                                            <td>
                                                                <i class="nav-icon i-Close-Window font-weight-bold text-danger" style="cursor: pointer"
                                                                
                                                                    >
                                                                </i>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                            <div class="col-sm-12">
                                                <div class="row">
                                                    <div class="col-sm-8">
                                                        <div class="form-group row">
                                                            <div class="col-sm-11"  style="border-right: 1px solid white;">
                                                            </div>
                                                            <div class="col-sm-1"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- en table  -->
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">Total a Pagar:</label>
                                                            <input type="text" class="form-control" id="total_pagar" v-model="guia.total_pagar" placeholder="Ingrese un monto">
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">A Cuenta:</label>
                                                            <input type="text" class="form-control" id="acuenta" v-model="guia.acuenta" placeholder="ingrese un monto">
                                                        </div>
                                                    </div>
                                                </div>	
                                            </div>
                                             <div class="col-md-12">
                                                <label class="text-body">Choferes</label>
                                                <fieldset class="form-group mb-3 d-flex">
                                                    <select class="js-example-basic-single js-states form-control bg-transparent" v-model='guia.chofer_id' @change="appendChild($event,'select')">
                                                        <option value="">Selecciona una opcion:</option>
                                                        <option v-for='chofer in guia_dropdowns' :value='chofer.id'
                                                            v-bind:selected="chofer.id"
                                                            v-bind:key="chofer.id"
                                                            v-if="chofer.status == 1"
                                                            >{{ chofer.nombre }}
                                                        </option>
                                                    </select>

                                                </fieldset>
                                                <small class="form-text text-danger" v-if="errors.has('chofer_id')" v-text="errors.get('chofer')"></small>
                                            </div>
                                            <div>
                                                <button type="submit" class="btn btn-primary" @click.prevent="registrarChofer()">Registrar chofer</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import ErrorHandling from "./../../ErrorHandling";
  import axios from 'axios';

  export default {
    data() {
      return {
        activeItem: 'info-tab',
            guia: {
                numero: '',
                fecha: null,
                lugar: '',
                remitente : '',
                destino: '',
                consignatario: '',
                telefono: '',
                direccion: '',
                telefono2: '',
                total_pagar: '',
                acuenta: '',
                chofer_id : '',
                productos: [],
            },
        
        ClipboardEvent: '',
        request_method : '',
        token: [],
        guia_dropdowns:[],

        nbultos: '',
        descripcion: '',
        kilos: null,
        total: '',
        guia_id: '',
      

        errors: new ErrorHandling(),
        csrf: document.querySelector('meta[name="csrf-token"]').getAttribute("content")
      }
    },
    methods: {
        guiadropdowns(page_url) {
            this.$parent.loading = true;
            page_url = page_url || "/api/guias/create";
            axios.get(page_url, this.token).then(res => {
                this.guia_dropdowns = res.data.data;
                this.guia_id = res.data.numero.id + 1;
                console.log(res.data.data);
            }).finally(() => (this.$parent.loading = false));
        },
        appendChild(e,select){
            this.findChild(e.target.value,select);
        },
      registrarChofer() {
        // Código para registrar el chofer en la base de datos
        var url = '/api/guias/add_guias';
        console.log(this.guia);
        this.request_method = 'post'
        
        axios[this.request_method](url, this.guia, this.token)
        .then(res => {
            if (res.data.status == true){
                this.$toaster.success(res.data.message);
                setTimeout(() => { this.$router.push('/admin/add-guias'); }, 1000);
            } else {
                        this.$toaster.error(res.data.message)
                    }
        })
        .catch(error => {
					this.error_message = '';
					this.errors = new ErrorHandling();
					if (error.response.status == 422) {
						if(error.response.data.status == 'Error'){
                        this.$toaster.error(error.response.data.message)
						}
						else{
							this.errors.record(error.response.data.errors);
						}
					}
				}).finally(() => (this.$parent.loading = false));
        console.log('Guia registrada');
        this.numero = '';
        this.fecha = '';
        this.lugar = '';
        this.remitente = '';
        this.destino = '';
        this.consignatario = '';
        this.telefono = '';
        this.direccion = '';
        this.telefono2 = '';
        this.total_pagar = '';
        this.acuenta = '';
        this.chofer_id = ''; 

         
      },
       agregarProducto() {
        
        this.guia.productos.push({ nbultos: this.nbultos, descripcion: this.descripcion, kilos: this.kilos, total: this.total, guia_id: this.guia_id });
        this.nbultos = '';
        this.descripcion = '';
        this.kilos = '';
        this.total = '';
      
      },
      

    },
    computed: {},
    components: {
    },
    mounted() { 
        var token = localStorage.getItem('token');
        this.token = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
         this.guiadropdowns();
        
    }
  };
  </script>
  