var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group row" }, [
    _c("div", { staticClass: "col-md-12" }, [
      _c("label", [_vm._v("Sujeta")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.emailsetting.subject,
              expression: "emailsetting.subject"
            }
          ],
          ref: "subject",
          staticClass: "form-control border-dark",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.emailsetting.subject },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.emailsetting, "subject", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _vm.errors.has("subject")
          ? _c("small", {
              staticClass: "form-text text-danger",
              domProps: { textContent: _vm._s(_vm.errors.get("subject")) }
            })
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c("label", [_vm._v("Cuerpo")]),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.emailsetting.body,
            expression: "emailsetting.body"
          }
        ],
        ref: "body",
        staticClass: "form-control border-dark",
        attrs: { placeholder: "" },
        domProps: { value: _vm.emailsetting.body },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.emailsetting, "body", $event.target.value)
          }
        }
      }),
      _vm._v(" "),
      _vm.errors.has("body")
        ? _c("small", {
            staticClass: "form-text text-danger",
            domProps: { textContent: _vm._s(_vm.errors.get("body")) }
          })
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c("br"),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "submit" },
          on: {
            click: function($event) {
              return _vm.updateSetting()
            }
          }
        },
        [_vm._v("Submit")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }