var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group row" }, [
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Estilo de encabezado")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.theme.header_style,
                expression: "theme.header_style"
              }
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.theme,
                  "header_style",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "style1" } }, [_vm._v("Estilo 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style2" } }, [_vm._v("Estilo 2")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style3" } }, [_vm._v("Estilo 3")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style4" } }, [_vm._v("Estilo 4")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style5" } }, [_vm._v("Estilo 5")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style6" } }, [_vm._v("Estilo 6")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style7" } }, [_vm._v("Estilo 7")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style8" } }, [_vm._v("Estilo 8")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Estilo deslizante")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.theme.slider_style,
                expression: "theme.slider_style"
              }
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.theme,
                  "slider_style",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "style1" } }, [_vm._v("Estilo 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style2" } }, [_vm._v("Estilo 2")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style3" } }, [_vm._v("Estilo 3")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style4" } }, [_vm._v("Estilo 4")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style5" } }, [_vm._v("Estilo 5")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Banner")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.theme.banner_style,
                expression: "theme.banner_style"
              }
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.theme,
                  "banner_style",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "style1" } }, [_vm._v("Estilo 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style2" } }, [_vm._v("Estilo 2")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style3" } }, [_vm._v("Estilo 3")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style4" } }, [_vm._v("Estilo 4")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style5" } }, [_vm._v("Estilo 5")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style6" } }, [_vm._v("Estilo 6")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style7" } }, [_vm._v("Estilo 7")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style8" } }, [_vm._v("Estilo 8")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style9" } }, [_vm._v("Estilo 9")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style10" } }, [
              _vm._v("Estilo 10")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style11" } }, [
              _vm._v("Estilo 11")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style12" } }, [
              _vm._v("Estilo 12")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style13" } }, [
              _vm._v("Estilo 13")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style14" } }, [
              _vm._v("Estilo 14")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style15" } }, [
              _vm._v("Estilo 15")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style16" } }, [
              _vm._v("Estilo 16")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style17" } }, [
              _vm._v("Estilo 17")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style18" } }, [
              _vm._v("Estilo 18")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style19" } }, [_vm._v("Estilo 19")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Pie de Pagina")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.theme.Footer_style,
                expression: "theme.Footer_style"
              }
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.theme,
                  "Footer_style",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "style1" } }, [_vm._v("Estilo 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style2" } }, [_vm._v("Estilo 2")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style3" } }, [_vm._v("Estilo 3")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style4" } }, [_vm._v("Estilo 4")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style5" } }, [_vm._v("Estilo 5")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style6" } }, [_vm._v("Estilo 6")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style7" } }, [_vm._v("Estilo 7")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style8" } }, [_vm._v("Estilo 8")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style9" } }, [_vm._v("Estilo 9")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style10" } }, [_vm._v("Estilo 10")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("\nEstilo de tarjeta")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.theme.card_style,
                expression: "theme.card_style"
              }
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.theme,
                  "card_style",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "style1" } }, [_vm._v("Estilo 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style2" } }, [_vm._v("Estilo 2")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style3" } }, [_vm._v("Estilo 3")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style4" } }, [_vm._v("Estilo 4")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style5" } }, [_vm._v("Estilo 5")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style6" } }, [_vm._v("Estilo 6")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style7" } }, [_vm._v("Estilo 7")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style8" } }, [_vm._v("Estilo 8")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style9" } }, [_vm._v("Estilo 9")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style10" } }, [
              _vm._v("Estilo 10")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style11" } }, [
              _vm._v("Estilo 11")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style12" } }, [
              _vm._v("Estilo 12")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style13" } }, [
              _vm._v("Estilo 13")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style14" } }, [
              _vm._v("Estilo 14")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style15" } }, [
              _vm._v("Estilo 15")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style16" } }, [
              _vm._v("Estilo 16")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style17" } }, [
              _vm._v("Estilo 17")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style18" } }, [
              _vm._v("Estilo 18")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style19" } }, [
              _vm._v("Estilo 19")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style20" } }, [_vm._v("Estilo 20")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Estilo de página de tarjeta")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.theme.cart_page,
                expression: "theme.cart_page"
              }
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.theme,
                  "cart_page",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "style1" } }, [_vm._v("Estilo 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style2" } }, [_vm._v("Estilo 2")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Sobre nosotros Estilo de página")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.theme.about_us,
                expression: "theme.about_us"
              }
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.theme,
                  "about_us",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "style1" } }, [_vm._v("Estilo 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style2" } }, [_vm._v("Estilo 2")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style3" } }, [_vm._v("Estilo 3")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Estilo de página de blog")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.theme.blog,
                expression: "theme.blog"
              }
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.theme,
                  "blog",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "style1" } }, [_vm._v("Estilo 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style2" } }, [_vm._v("Estilo 2")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Contáctenos Estilo de página")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.theme.contact_us,
                expression: "theme.contact_us"
              }
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.theme,
                  "contact_us",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "style1" } }, [_vm._v("Estilo 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style2" } }, [_vm._v("Estilo 2")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("\nEstilo de página de inicio de sesión")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.theme.login,
                expression: "theme.login"
              }
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.theme,
                  "login",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "style1" } }, [_vm._v("Estilo 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style2" } }, [_vm._v("Estilo 2")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Estilo de página del producto")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.theme.product_detail,
                expression: "theme.product_detail"
              }
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.theme,
                  "product_detail",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "style1" } }, [_vm._v("Estilo 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style2" } }, [_vm._v("Estilo 2")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style3" } }, [_vm._v("Estilo 3")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style4" } }, [_vm._v("Estilo 4")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style5" } }, [_vm._v("Estilo 5")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Estilo de página de la página de la tienda")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.theme.shop,
                expression: "theme.shop"
              }
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.theme,
                  "shop",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "style1" } }, [_vm._v("Estilo 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style2" } }, [_vm._v("Estilo 2")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style3" } }, [_vm._v("Estilo 3")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style4" } }, [_vm._v("Estilo 4")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style5" } }, [_vm._v("Estilo 5")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "submit" },
          on: {
            click: function($event) {
              return _vm.updateSetting()
            }
          }
        },
        [_vm._v("Guardar")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }